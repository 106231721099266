import type * as apiTypes from './alerts.api.types';

import * as alertUtils from 'utils/alert-utils';

import { fetch } from 'utils/api';
import { API_NOTIFICATION_TYPES } from './alerts.constants';

import queryString from 'utils/query-string';

export const fetchAllAlerts: apiTypes.FetchAllAlerts = params => {
  return fetch({
    url: `/alerts`,
    method: 'GET',
    dataType: 'alerts',
    params: params ? { ...params, alert_type: API_NOTIFICATION_TYPES.DOCUMENT } : params,
    paramsSerializer: (params: unknown) => queryString.stringify(params)
  });
};

export const updateAlert: apiTypes.UpdateAlert = (alertId, params) => {
  let sanitizedPayload = {};

  if (params?.search_args) {
    sanitizedPayload = {
      ...params,
      search_args: {
        ...(params?.search_args ?? {}),
        limit: undefined,
        offset: undefined,
        pathname: params.pathname ?? undefined,
        cai_category: alertUtils.safeGetAlertCAICategories(params?.search_args),
        category: alertUtils.safeGetAlertCategories(params?.search_args)
      }
    };
  } else {
    sanitizedPayload = params;
  }

  return fetch({
    url: `/alerts/${alertId}`,
    method: 'POST',
    params: sanitizedPayload,
    shouldHideErrorNotifications: true
  });
};

export const markAlertAsViewed: apiTypes.MarkAlertAsViewed = alertId => {
  return fetch({
    url: `/alerts/${alertId}/viewed`,
    method: 'POST',
    dataType: 'alerts'
  });
};

export const updateAlertEmailSettings: apiTypes.UpdateAlertEmailSettings = (alertId, params) => {
  return fetch({
    url: `/alerts_email_settings/${alertId}`,
    method: 'POST',
    params,
    dataType: 'alerts'
  });
};

export const createAlert: apiTypes.CreateAlert = params => {
  return fetch({
    url: `/alerts`,
    method: 'POST',
    params
  });
};

export const removeAlert: apiTypes.RemoveAlert = alertId => {
  return fetch({
    url: `/alerts/${alertId}`,
    method: 'DELETE',
    dataType: 'alerts'
  });
};

export const fetchRelatedAlertsDocuments: apiTypes.FetchRelatedAlertsDocuments = params => {
  return fetch({
    url: `/related_alerts_aggregation`,
    method: 'GET',
    dataType: 'alerts',
    shouldDisableQueryParamsArrayFormatting: true,
    params
  });
};

/**
 * Backwards compatability
 */
export const api_fetchAllAlerts = fetchAllAlerts;
export const api_updateAlert = updateAlert;
export const api_markAlertAsViewed = markAlertAsViewed;
export const api_updateAlertEmailSettings = updateAlertEmailSettings;
export const api_createAlert = createAlert;
export const api_removeAlert = removeAlert;
