import type { SelectOption } from '@compliance.ai/web-components';

export const getValuesFromOptions = <T>({
  isMulti,
  options,
  value
}: {
  isMulti?: boolean;
  options: SelectOption<T>[];
  value?: T | T[] | null;
}): SelectOption<T> | SelectOption<T>[] | null => {
  if (isMulti) {
    return options.filter(option => {
      return Array.isArray(value) ? value.includes(option.value as T) : value === option.value;
    });
  }

  return (
    options.find(option => {
      return value === option.value;
    }) ?? null
  );
};

export const extractValues = <T>(
  value?: SelectOption[] | SelectOption | object | null
): unknown | null => {
  if (Array.isArray(value)) {
    return value.map(value =>
      typeof value === 'string' ? value : 'value' in value ? value.value : value
    );
  }

  if (!value) {
    return null;
  }

  if (typeof value !== 'object') {
    return value;
  }

  return 'value' in value ? value?.value : value;
};

export const getPinnedOption = <T = unknown>(
  options: SelectOption<T> | SelectOption<T>[] | null
): SelectOption<T> | null => {
  if (Array.isArray(options)) {
    return options.filter(o => o.isPinned)[0] ?? null;
  }

  const option = options as SelectOption<T>;

  return (option as SelectOption<T>)?.isPinned ? option : null;
};
