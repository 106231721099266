import type { FC } from 'react';
import type { BucketSelectProps } from './BucketSelect.types';

import * as uiLib from '@compliance.ai/web-components';

import { useBucketSelectData, useBucketSelectHandlers, useBucketSelectQueries } from './hooks';

export const BucketSelect: FC<BucketSelectProps> = props => {
  const { localState, localActions, reduxState } = useBucketSelectData();

  const queries = useBucketSelectQueries({
    props,
    localState,
    reduxState
  });

  const handlers = useBucketSelectHandlers({ props, reduxState, localActions, queries });

  return (
    <uiLib.Select
      {...props}
      type={props.type ?? uiLib.SELECT_TYPES.DEFAULT}
      value={props.value ?? localState.selectedBucketOption}
      inputValue={localState.bucketNameInput}
      isDisabled={
        !localState.bucketNameInput && queries.fetchBucketsOptionsQuery.data?.length === 0
      }
      isLoading={queries.fetchBucketsOptionsQuery.isPending}
      options={queries.fetchBucketsOptionsQuery?.data}
      onChange={handlers.handleOptionChange}
      onInputChange={handlers.handleInputChange}
    />
  );
};
