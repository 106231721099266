import type { DiffTabData } from './useDiffTabData.types';

import * as errorUtils from 'utils/errors';
import * as queryKeys from 'constants/QueryKeys';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks';

export const useDiffTabQueries = ({ reduxState }: { reduxState: DiffTabData['reduxState'] }) => {
  const documentsReduxActions = useDocumentsReduxActions();

  const fetchDiffDocs = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
      queryKeys.QUERY_SECTIONS.DIFF_TAB
    ](reduxState.diffDocIds[0] || null, reduxState.diffDocIds[1] || null),
    queryFn: async () => {
      try {
        const [doc1, doc2] = await Promise.all([
          documentsReduxActions.fetchDocument(reduxState.diffDocIds[0]),
          documentsReduxActions.fetchDocument(reduxState.diffDocIds[1])
        ]);
        documentsReduxActions.addDocsToDiff([doc1, doc2]);
        return [doc1, doc2];
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    enabled: Boolean(reduxState.diffDocIds[0] && reduxState.diffDocIds[1]),
    refetchOnWindowFocus: false
  });

  return {
    fetchDiffDocs
  };
};
