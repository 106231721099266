import type { PersistedClient, Persister } from '@tanstack/react-query-persist-client';

import lodash from 'lodash';

import { get, set, del } from 'idb-keyval';

export const createIDBPersister = ({ key = 'reactQuery' }: { key: IDBValidKey }) => {
  return {
    persistClient: lodash.debounce(async function (client: PersistedClient) {
      await set(key, client);
    }, 1000),
    restoreClient: async () => {
      return await get<PersistedClient>(key);
    },
    removeClient: async () => {
      await del(key);
    }
  } as Persister;
};
