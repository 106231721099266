import type { ProgressiveSentenceNotesArgs } from './useProgressiveSentenceNotes.types';
import type { ProgressiveSentenceNotesData } from './useProgressiveSentenceNotesData.types';
import type { NormalizedSentenceNotesResponse } from '../../sentenceNotes.types';

import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';
import * as helpers from './useProgressiveSentenceNotesQueries.helpers';
import * as sentenceNotesConstants from '../../sentenceNotes.constants';

import { INITIAL_PARAMS } from './useProgressiveSentenceNotes.constants';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useSentenceNotesReduxActions } from 'shared/features/sentenceNotes/hooks';

export const useProgressiveSentenceNotesQueries = ({
  args,
  localState,
  localActions
}: {
  args: Pick<
    ProgressiveSentenceNotesArgs,
    'documentId' | 'type' | 'sentenceNoteId' | 'onFetchComplete'
  >;
  localState: ProgressiveSentenceNotesData['localState'];
  localActions: ProgressiveSentenceNotesData['localActions'];
}) => {
  const sentenceNotesReduxActions = useSentenceNotesReduxActions();

  const fetchSystemGeneratedSentenceNotes = useQuery({
    enabled: false,
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[
      queryKeys.QUERY_KEYS.SYSTEM_GENERATED_SENTENCE_NOTES
    ][queryKeys.QUERY_SECTIONS.RIGHT_PANEL](args.documentId),
    queryFn: async () => {
      try {
        const data = await sentenceNotesReduxActions.fetchSystemGeneratedSentenceNotes({
          documentId: args.documentId
        });

        return data;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    gcTime: 0,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false
  });

  const fetchSentenceNoteById = useQuery({
    enabled: Boolean(!localState.hasFetchedSingleSentenceNote && args.sentenceNoteId),
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
      queryKeys.QUERY_SECTIONS.RIGHT_PANEL
    ]({
      id: args.sentenceNoteId
    }),
    queryFn: async () => {
      try {
        const response = await sentenceNotesReduxActions.getSentenceNotes({
          params: {
            id: args.sentenceNoteId
          }
        });

        localActions.setHasFetchedSingleSentenceNote(true);

        return response;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    gcTime: 0,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false
  });

  const fetchSentenceNotes = useInfiniteQuery<NormalizedSentenceNotesResponse, Error>({
    enabled:
      localState.hasFetchedSingleSentenceNote ||
      (!localState.hasFetchedSingleSentenceNote && !args.sentenceNoteId),
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
      queryKeys.QUERY_SECTIONS.RIGHT_PANEL
    ](
      helpers.getRequestParams({
        args: args,
        prevPageParams: {}
      })
    ),
    initialPageParam: INITIAL_PARAMS,
    queryFn: async ({ pageParam }) => {
      try {
        const params = helpers.getRequestParams({
          args: args,
          prevPageParams: pageParam as Parameters<
            ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes']
          >[0]['params']
        });

        const response = await sentenceNotesReduxActions.getSentenceNotes({
          params
        });

        localActions.setIsInitialFetchOfList(false);
        localActions.setTotalCount(response.total);

        if (
          response.offset + INITIAL_PARAMS.limit >= response.total &&
          args.type === sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS
        ) {
          await fetchSystemGeneratedSentenceNotes.refetch();

          args.onFetchComplete?.();
        }

        return response;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    gcTime: 0,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.offset + INITIAL_PARAMS.limit >= lastPage.total) {
        return undefined;
      }
      return {
        ...(lastPageParam as Record<string, unknown>),
        offset: lastPage.offset + INITIAL_PARAMS.limit
      };
    }
  });

  return {
    fetchSystemGeneratedSentenceNotes,
    fetchSentenceNotes,
    fetchSentenceNoteById
  };
};
