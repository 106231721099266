import type { SelectOption } from '@compliance.ai/web-components';
import type { TextMultiSelectProps } from '../TextMultiSelect.types';
import type { TextMultiSelectHandlers } from './useTextMultiselectHandlers.types';

export const useTextMultiSelectHandlers = ({
  props
}: {
  props: TextMultiSelectProps;
}): TextMultiSelectHandlers => {
  const handleCreateOption: TextMultiSelectHandlers['handleCreateOption'] = inputString => {
    props.onChange?.([
      ...((props.value as SelectOption[]) || []),
      { value: inputString, label: inputString }
    ]);
  };

  return {
    handleCreateOption
  };
};
