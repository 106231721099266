import * as workflowApi from 'shared/features/workflow/workflow.api';
import * as commentsApi from 'shared/features/comments/comments.api';

import { QUERY_KEYS } from 'constants/QueryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useWorkflowReduxActions } from 'shared/features/workflow/hooks';

export const useWorkflowPanelTaskMutations = () => {
  const queryClient = useQueryClient();
  const workflowActions = useWorkflowReduxActions();

  const editDocumentTaskMutation = useMutation<
    Awaited<ReturnType<typeof workflowApi.api_editDocumentTask>>,
    Error,
    Parameters<typeof workflowApi.api_editDocumentTask>
  >({
    mutationFn: variables => workflowApi.api_editDocumentTask(...variables),
    mutationKey: [QUERY_KEYS.DOC_TASKS],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GROUPED_TASKS] });
      workflowActions.toggleDocTaskRefetchTrigger();
    }
  });

  const updateDropdownMutation = useMutation<
    Awaited<ReturnType<typeof workflowApi.updateTaskDropdown>>,
    Error,
    Parameters<typeof workflowApi.updateTaskDropdown>
  >({
    mutationFn: variables => workflowApi.updateTaskDropdown(...variables),
    mutationKey: [QUERY_KEYS.DROPDOWNS],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] })
  });

  const createCommentMutation = useMutation<
    Awaited<ReturnType<typeof commentsApi.createComment>>,
    Error,
    Parameters<typeof commentsApi.createComment>
  >({
    mutationFn: variables => commentsApi.createComment(...variables),
    mutationKey: [QUERY_KEYS.COMMENTS],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] })
  });

  const editCommentMutation = useMutation<
    Awaited<ReturnType<typeof commentsApi.editComment>>,
    Error,
    Parameters<typeof commentsApi.editComment>
  >({
    mutationFn: variables => commentsApi.editComment(...variables),
    mutationKey: [QUERY_KEYS.COMMENTS],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] })
  });

  const deleteCommentMutation = useMutation<
    Awaited<ReturnType<typeof commentsApi.deleteComment>>,
    Error,
    Parameters<typeof commentsApi.deleteComment>
  >({
    mutationFn: variables => commentsApi.deleteComment(...variables),
    mutationKey: [QUERY_KEYS.COMMENTS],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] })
  });

  const createDocumentTaskCommentThreadMutation = useMutation<
    Awaited<ReturnType<typeof commentsApi.createDocumentTaskCommentThread>>,
    Error,
    Parameters<typeof commentsApi.createDocumentTaskCommentThread>
  >({
    mutationFn: variables => commentsApi.createDocumentTaskCommentThread(...variables),
    mutationKey: [QUERY_KEYS.COMMENTS],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] })
  });

  return {
    editDocumentTaskMutation,
    updateDropdownMutation,
    createCommentMutation,
    editCommentMutation,
    deleteCommentMutation,
    createDocumentTaskCommentThreadMutation
  };
};
