import type { CommentsProps } from '../Comments.types';
import type {
  NormalizedComment,
  NormalizedCommentThread
} from 'shared/features/comments/comments.types';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as commentsApi from 'shared/features/comments/comments.api';
import * as errorUtils from 'utils/errors';
import * as queryKeys from 'constants/QueryKeys';

export const useCommentsMutations = ({
  props
}: {
  props: Pick<CommentsProps, 'sentenceNoteId'>;
}) => {
  const queryClient = useQueryClient();

  const createCommentThread = useMutation<void, Error, NormalizedComment['richtext']>({
    mutationFn: async richtext => {
      await commentsApi.createSentenceNoteCommentThread({
        richtext: richtext,
        sentence_note_id: props.sentenceNoteId
      } as Parameters<typeof commentsApi.createSentenceNoteCommentThread>[0]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
          queryKeys.QUERY_SECTIONS.SENTENCE_NOTE_CONTENT
        ](props.sentenceNoteId)
      });
    },
    onError: errorUtils.logReactQueryError
  });

  const createComment = useMutation<
    void,
    Error,
    {
      richtext: NormalizedComment['richtext'];
      commentThreadId: NormalizedCommentThread['id'];
    }
  >({
    mutationFn: async ({ richtext, commentThreadId }) => {
      await commentsApi.createComment({
        richtext: richtext,
        comment_thread_id: commentThreadId
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
          queryKeys.QUERY_SECTIONS.SENTENCE_NOTE_CONTENT
        ](props.sentenceNoteId)
      });
    },
    onError: errorUtils.logReactQueryError
  });

  const deleteComment = useMutation<void, Error, NormalizedComment>({
    mutationFn: async comment => {
      await commentsApi.deleteComment(comment.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
          queryKeys.QUERY_SECTIONS.SENTENCE_NOTE_CONTENT
        ](props.sentenceNoteId)
      });
    },
    onError: errorUtils.logReactQueryError
  });

  const editComment = useMutation<
    void,
    Error,
    {
      commentId: NormalizedComment['id'];
      richtext: NormalizedComment['richtext'];
    }
  >({
    mutationFn: async ({ commentId, richtext }) => {
      await commentsApi.editComment(commentId, {
        richtext
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
          queryKeys.QUERY_SECTIONS.SENTENCE_NOTE_CONTENT
        ](props.sentenceNoteId)
      });
    },
    onError: errorUtils.logReactQueryError
  });

  return {
    createCommentThread,
    createComment,
    deleteComment,
    editComment,

    isLoading:
      createCommentThread.isPending ||
      createComment.isPending ||
      deleteComment.isPending ||
      editComment.isPending
  };
};
