import type { SentenceNoteContentProps } from '../SentenceNoteContent.types';

import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';
import * as sentenceNotesApi from 'shared/features/sentenceNotes/sentenceNotes.api';
import * as sentenceNotesHelpers from 'shared/features/sentenceNotes/sentenceNotes.helpers';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const useSentenceNoteContentQueries = ({
  props
}: {
  props: Pick<SentenceNoteContentProps, 'id'>;
}) => {
  const fetchSentenceNote = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
      queryKeys.QUERY_SECTIONS.SENTENCE_NOTE_CONTENT
    ](props.id),
    queryFn: async () => {
      try {
        const data = await sentenceNotesApi.getSentenceNoteById(props.id);
        return data;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    retry: false,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    select: data => ({
      sentenceNote: data,
      attachments: sentenceNotesHelpers.normalizeAttachments(data.attachments)
    })
  });

  return {
    fetchSentenceNote
  };
};
