import type { DocumentFromResponse } from '../../documents.types';

import { useDocumentsReduxActions } from '../index';
import { useQuery } from '@tanstack/react-query';

import * as queryKeys from 'constants/QueryKeys';
import * as errorsUtils from 'utils/errors';

export const useDocDiff = ({
  docIdsToCompare
}: {
  docIdsToCompare: {
    docIdV1: DocumentFromResponse['id'];
    docIdV2: DocumentFromResponse['id'];
  };
}) => {
  const { docIdV1, docIdV2 } = docIdsToCompare;
  const reduxActions = useDocumentsReduxActions();

  const fetchDiffQuery = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
      queryKeys.QUERY_SECTIONS.DIFF_FILE
    ](docIdV1, docIdV2),
    queryFn: async () => {
      try {
        const data = await reduxActions.fetchDiff({ doc_id_v1: docIdV1, doc_id_v2: docIdV2 });
        return data;
      } catch (error) {
        errorsUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    enabled: Boolean(docIdV1 && docIdV2),
    refetchOnWindowFocus: false
  });

  return {
    diff: ((fetchDiffQuery.data as unknown) as string) ?? null,
    isReady: !fetchDiffQuery.isFetching && Boolean(fetchDiffQuery.data),
    isFetching: fetchDiffQuery.isFetching
  };
};
