import type { EitlLabelsSelectProps } from '../EitlLabelsSelect.types';
import type { EitlLabelsSelectData } from './useEitlLabelsSelectData.types';
import type { EitlLabelOption } from '../../../shared/features/eitlLabels/eitlLabels.types';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'constants/QueryKeys';

import * as editLabelsApi from 'shared/features/eitlLabels/eitlLabels.api';
import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';
import * as constants from '../EitlLabelsSelect.constants';
import * as errorUtils from 'utils/errors';

import { useQuery } from '@tanstack/react-query';

export const useEitlLabelsSelectQueries = ({
  props,
  localActions,
  formattedData
}: {
  props: Pick<EitlLabelsSelectProps, 'value'>;
  localActions: EitlLabelsSelectData['localActions'];
  formattedData: EitlLabelsSelectData['formattedData'];
}) => {
  const fetchPropsValueQuery = useQuery<EitlLabelOption[], Error, EitlLabelOption[]>({
    queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.EITL_LABELS][QUERY_SECTIONS.EITL_LABELS_SELECT](
      props.value ?? []
    ),
    queryFn: async () => {
      try {
        if (!formattedData.labelsToFetchIds.length) {
          localActions.setValue([]);
          return [];
        }

        localActions.setIsLoading(true);

        const response = await editLabelsApi.getEitlLabels({
          id: formattedData.labelsToFetchIds
        });

        const options = eitlLabelsApiHelpers.formatEitlLabelsOptions(response);

        if (formattedData.isNoLabelsOptionSelected) {
          const formattedOptions = [constants.EITL_LABELS_NO_LABELS_OPTION, ...options];

          localActions.setValue(formattedOptions);
          return formattedOptions;
        }

        localActions.setValue(options);
        return options;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      } finally {
        localActions.setIsLoading(false);
      }
    },
    enabled: Boolean(props.value),
    refetchOnWindowFocus: false
  });

  return {
    fetchPropsValueQuery
  };
};
