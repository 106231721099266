import type { UserFromResponse } from '../user/user.types';
import type * as organizationsTypes from './organizations.types';
import type {
  OrgMemberOption,
  OrgLabelFromResponse,
  OrgLabelOption,
  OrgLabelBucketOption,
  OrgTeamFromResponse,
  NormalizedOrgTeam,
  TeamMemberFromResponse
} from './organizations.types';
import type { RoleFromResponse } from '../user/user.types';

import * as orgConstants from './organizations.constants';
import * as sortConstants from 'constants/Sort';
import * as bucketsConstants from '../buckets/buckets.constants';
import * as sortUtils from 'utils/sort';

export const formatOrgMemberOption = (
  orgMember: UserFromResponse | TeamMemberFromResponse
): OrgMemberOption => ({
  value: orgMember.id,
  label: orgMember.email
});

export const filterTeamMembers = ({
  teamMembers,
  filters
}: {
  teamMembers: UserFromResponse[];
  filters: {
    email?: UserFromResponse['email'];
    name?: string;
    roleIds?: RoleFromResponse['id'][];
    isActive?: UserFromResponse['active'];
  };
}) => {
  return teamMembers.filter(teamMember => {
    const hasMatchedEmail = teamMember.email.includes(filters.email ?? '');

    const hasMatchedName = `${teamMember.first_name} ${teamMember.last_name}`.includes(
      filters.name ?? ''
    );

    const hasMatchedStatus =
      typeof filters.isActive === 'boolean' ? teamMember.active === filters.isActive : true;

    const hasMatchedRole = filters.roleIds?.length
      ? filters.roleIds?.some(roleId => {
          return teamMember.organization_roles.find(role => role.id === roleId);
        })
      : true;

    return (hasMatchedEmail || hasMatchedName) && hasMatchedStatus && hasMatchedRole;
  });
};

export const sortTeamMembers = ({
  teamMembers,
  sortField,
  sortOrder
}: {
  teamMembers: UserFromResponse[];
  sortField?: orgConstants.TEAM_MEMBER_SORT_FIELD;
  sortOrder?: sortConstants.SORT_ORDER;
}) => {
  return teamMembers.sort((memberA, memberB) => {
    const memberAForCurrentOrder = sortOrder === sortConstants.SORT_ORDER.ASC ? memberA : memberB;
    const memberBForCurrentOrder = sortOrder === sortConstants.SORT_ORDER.ASC ? memberB : memberA;

    switch (sortField) {
      case orgConstants.TEAM_MEMBER_SORT_FIELD.EMAIL: {
        return memberAForCurrentOrder.email.localeCompare(memberBForCurrentOrder.email);
      }

      case orgConstants.TEAM_MEMBER_SORT_FIELD.NAME: {
        return `${memberAForCurrentOrder.first_name} ${memberAForCurrentOrder.last_name}`.localeCompare(
          `${memberBForCurrentOrder.first_name} ${memberBForCurrentOrder.last_name}`
        );
      }

      case orgConstants.TEAM_MEMBER_SORT_FIELD.STATUS: {
        return memberAForCurrentOrder.active ? 1 : -1;
      }

      case orgConstants.TEAM_MEMBER_SORT_FIELD.TEAM: {
        const memberATeamNames = memberAForCurrentOrder.teams.map(team => team.name).join(' ');
        const memberBTeamNames = memberBForCurrentOrder.teams.map(team => team.name).join(' ');

        return memberATeamNames.localeCompare(memberBTeamNames);
      }

      case orgConstants.TEAM_MEMBER_SORT_FIELD.ROLE: {
        const memberARoles = memberAForCurrentOrder.organization_roles
          .map(role => role.name)
          .join(' ');
        const memberBRoles = memberBForCurrentOrder.organization_roles
          .map(role => role.name)
          .join(' ');

        return memberARoles.localeCompare(memberBRoles);
      }

      default: {
        return 0;
      }
    }
  });
};

export const formatOrgLabelOption = (orgLabel: OrgLabelFromResponse): OrgLabelOption => ({
  id: orgLabel.value,
  name: orgLabel.label,
  label: orgLabel.label,
  value: orgLabel.value
});

export const getOrgLabelBucketId = ({ bucket }: OrgLabelFromResponse): OrgLabelBucketOption['id'] =>
  bucket?.bucket_id ?? null;

export const getOrgLabelBucketName = ({
  bucket
}: OrgLabelFromResponse): OrgLabelBucketOption['name'] =>
  bucket ? bucket.bucket_name : bucketsConstants.DEFAULT_BUCKET_NAME;

export const formatOrgLabelBucket = (orgLabel: OrgLabelFromResponse): OrgLabelBucketOption => ({
  id: getOrgLabelBucketId(orgLabel),
  name: getOrgLabelBucketName(orgLabel),
  label: getOrgLabelBucketName(orgLabel),
  value: getOrgLabelBucketId(orgLabel)
});

export const sortBucketsByLabel = (
  a: Pick<OrgLabelBucketOption, 'label'>,
  b: Pick<OrgLabelBucketOption, 'label'>
): number => {
  if (a.label === bucketsConstants.DEFAULT_BUCKET_NAME) {
    return -1;
  }

  if (b.label === bucketsConstants.DEFAULT_BUCKET_NAME) {
    return 1;
  }

  return sortUtils.alphabeticallyByLabel(a, b);
};

export const normalizeOrgTeam = (team: OrgTeamFromResponse): NormalizedOrgTeam => {
  return {
    id: team.id,
    name: team.name,
    orgId: team.org_id,
    memberCount: team.member_count,
    groupingType: team.grouping_type || orgConstants.DEFAULT_TEAM_GROUP
  };
};

export const normalizeOrgMember = ({
  orgMember
}: {
  orgMember: organizationsTypes.OrgMemberFromResponse;
}): organizationsTypes.NormalizedOrgMember => {
  return {
    id: orgMember.id,
    email: orgMember.email,
    firstName: orgMember.first_name,
    lastName: orgMember.last_name,
    orgUserRoles: orgMember.org_user_roles,
    isActive: orgMember.active,
    invitationStatus: null
  };
};

export const normalizeOrgInvite = ({
  orgInvite
}: {
  orgInvite: organizationsTypes.OrgInviteFromResponse;
}): organizationsTypes.NormalizedOrgMember => {
  return {
    id: orgInvite.id,
    orgUserRoles: orgInvite.org_user_roles?.map(role => ({
      name: '',
      id: Number(role),
      description: null,
      permissions: []
    })),
    email: orgInvite.email,
    invitationStatus: orgInvite.invitation_status,
    firstName: null,
    lastName: null,
    isActive: false
  };
};

export const normalizeOrganizationMembersResponse = ({
  response
}: {
  response: organizationsTypes.OrgMembersResponse;
}): organizationsTypes.NormalizedOrgMembersResponse => {
  return {
    count: response.count,
    offset: response.offset,
    total: response.total,
    limit: response.limit,
    results: response.results.map(orgMember => normalizeOrgMember({ orgMember })),
    orgInvites: response.org_invitations.map(orgInvite => normalizeOrgInvite({ orgInvite }))
  };
};

export const normalizeAreaOfFocus = ({
  areaOfFocus
}: {
  areaOfFocus: organizationsTypes.AreaOfFocusFromResponse;
}): organizationsTypes.NormalizedAreaOfFocus => {
  return {
    id: areaOfFocus.id,
    name: areaOfFocus.name,
    teamId: areaOfFocus.team_id,
    productServiceId: areaOfFocus.product_service_id
  };
};

export const normalizeAreasOfFocusResponse = ({
  response
}: {
  response: organizationsTypes.AreasOfFocusResponse;
}): organizationsTypes.NormalizedAreasOfFocusResponse => {
  return {
    count: response.count,
    offset: response.offset,
    total: response.total,
    limit: response.limit,
    results: response.results.map(areaOfFocus => normalizeAreaOfFocus({ areaOfFocus }))
  };
};

export const formatNewOrgResponse = (response: organizationsTypes.OrganizationFromResponse) => {
  return {
    organization: {
      ...response,
      organization_members:
        response.organization_members?.map(member => ({
          ...member,
          organization_roles: response.roles.filter(role =>
            member.org_user_roles.some(orgRole => orgRole.id === role.id)
          )
        })) || []
    }
  };
};

export const formatTeamsToGroups = (teams: NormalizedOrgTeam[]) => {
  return [...new Set(teams.map(team => team.groupingType || orgConstants.DEFAULT_TEAM_GROUP))].sort(
    (a, b) => {
      const isFirstCorporate = a === orgConstants.DEFAULT_TEAM_GROUP;
      const isSecondCorporate = b === orgConstants.DEFAULT_TEAM_GROUP;
      if (isFirstCorporate) {
        return -1;
      }
      if (isSecondCorporate) {
        return 1;
      }
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    }
  );
};
