import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { useDocumentDetailsRightPanelConfigData } from './useDocumentDetailsRightPanelConfigData';
import { useDocumentDetailsRightPanelConfigQueries } from './useDocumentDetailsRightPanelConfigQueries';
import { useDocumentDetailsRightPanelConfigHandlers } from './useDocumentDetailsRightPanelConfigHandlers';
import { useDocumentDetailsRightPanelConfigTabs } from './useDocumentDetailsRightPanelConfigTabs';

export const useDocumentDetailsRightPanelConfig = (): Required<
  ComponentProps<typeof uiLib.AppNavigation>
>['rightPanelConfig'] => {
  const { reduxState, formattedData } = useDocumentDetailsRightPanelConfigData();

  const queries = useDocumentDetailsRightPanelConfigQueries({
    reduxState
  });

  const handlers = useDocumentDetailsRightPanelConfigHandlers({
    queries,
    reduxState
  });

  const tabs = useDocumentDetailsRightPanelConfigTabs({
    reduxState,
    queries,

    events: {
      onTabClick: handlers.handleTabClick,
      onRestrictedTabClick: handlers.handleRestrictedTabClick
    }
  });

  return {
    tabs,
    contentTitle: (
      <elements.DocumentDetailsContentTitle
        document={queries.fetchDocumentDetails.data?.document ?? null}
        isFetchingDocument={queries.fetchDocumentDetails.isFetching || formattedData.isUserLoading}
      />
    ),
    content: (
      <elements.DocumentDetailsContent
        document={queries.fetchDocumentDetails.data?.document ?? null}
        isFetchingDocument={queries.fetchDocumentDetails.isFetching || formattedData.isUserLoading}
      />
    )
  };
};
