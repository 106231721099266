import type { DocumentLabelsProps } from '../DocumentLabels.types';
import type { DocumentLabelsData } from './useDocumentLabelsData.types';

import * as documentNotesApi from 'shared/features/documentNotes/documentNotes.api';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';
import * as helpers from './useDocumentLabelsQueries.helpers';

import { useQuery } from '@tanstack/react-query';

export const useDocumentLabelsQueries = ({
  props,
  reduxState
}: {
  props: Pick<DocumentLabelsProps, 'docId'>;
  reduxState: DocumentLabelsData['reduxState'];
}) => {
  const fetchDocumentNote = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENT_NOTES][
      queryKeys.QUERY_SECTIONS.DOCUMENT_LABELS
    ]({
      document_id: props.docId,
      limit: 1,
      offset: 0
    }),
    queryFn: async () => {
      try {
        const data = await documentNotesApi.fetchDocumentNotes({
          document_id: props.docId,
          limit: 1,
          offset: 0
        });
        return data;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    enabled: reduxState.isAuthenticated,
    select: helpers.formatDocumentNoteLabels,
    retry: false,
    refetchOnWindowFocus: false
  });

  return {
    fetchDocumentNote
  };
};
