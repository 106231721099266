import type * as apiTypes from './sentenceNotes.api.types';
import type * as types from './sentenceNotes.types';

import * as dateUtils from 'utils/date-utils';
import * as commentsHelpers from '../comments/comments.helpers';

export const normalizeAttachments = (
  attachments?:
    | apiTypes.DetailedSentenceNoteFromResponse['attachments']
    | apiTypes.SentenceNoteFromResponse['attachments']
): types.NormalizedSentenceNoteAttachment[] => {
  return (
    attachments?.map?.(attachment => ({
      id: attachment.id,
      fileSize: attachment.file_size,
      fileHash: attachment.file_hash,
      userFileName: attachment.user_file_name,
      maskedFileName: attachment.masked_file_name,
      url: attachment.url ?? '',
      deleted: attachment.deleted ?? false
    })) ?? []
  );
};

export const normalizeSentenceNotesResponse = (
  response: apiTypes.GetSentenceNotesResponse
): types.NormalizedSentenceNotesResponse => {
  return {
    count: response.count ?? 0,
    offset: response.offset ?? 0,
    total: response.total ?? 0,
    sentenceNotes: response.sentence_notes?.map?.(normalizeSentenceNote) ?? []
  };
};

export const normalizeSentenceNoteRelevance = (
  relevance: apiTypes.SentenceNoteFromResponse['relevance']
): types.NormalizedSentenceNote['relevance'] => {
  switch (relevance) {
    case 1:
      return true;
    case 0:
      return false;
    default:
      return null;
  }
};

export const normalizeSentenceNote = (
  sentenceNote: apiTypes.SentenceNoteFromResponse
): types.NormalizedSentenceNote => {
  return {
    id: sentenceNote.id,
    createdAt: dateUtils.isValidDate(sentenceNote.created_at)
      ? dateUtils.formatDate(sentenceNote.created_at)
      : '',
    lastUpdatedAt: dateUtils.isValidDate(sentenceNote.last_updated_at)
      ? dateUtils.formatDate(sentenceNote.last_updated_at)
      : '',
    isObligation: sentenceNote.is_obligation,
    attachments: normalizeAttachments(sentenceNote.attachments),
    commentThreads: (commentsHelpers.normalizeCommentThreads(
      sentenceNote.comment_threads
    ) as unknown) as types.NormalizedSentenceNote['commentThreads'],
    isEditable: sentenceNote.is_editable,
    isSystemObligation: sentenceNote.is_system_obligation,
    relevance: normalizeSentenceNoteRelevance(sentenceNote.relevance),
    firstSentenceId: sentenceNote.first_sentence_id,
    lastSentenceId: sentenceNote.last_sentence_id,
    leftOffset: sentenceNote.left_offset,
    rightOffset: sentenceNote.right_offset,
    sentencesText: sentenceNote.sentences_text,
    richText: sentenceNote.richtext,
    isHidden: sentenceNote.hidden,
    isDeleted: sentenceNote.deleted,
    labels: sentenceNote.labels,
    bucket: sentenceNote.bucket,
    document: sentenceNote.document,
    createdBy: sentenceNote.created_by,
    obligationProbability: sentenceNote.obligation_probability
  };
};

export const normalizeSystemGeneratedSentenceNote = (
  sentenceNote: apiTypes.SystemSentenceNoteFromResponse
): types.NormalizedSystemGeneratedSentenceNote => {
  return {
    id: null,
    isObligation: sentenceNote.is_obligation,
    isSystemObligation: sentenceNote.is_system_obligation,
    firstSentenceId: sentenceNote.first_sentence_id,
    lastSentenceId: sentenceNote.last_sentence_id,
    leftOffset: sentenceNote.left_offset,
    rightOffset: sentenceNote.right_offset,
    sentencesText: sentenceNote.sentences_text,
    obligationProbability: sentenceNote.obligation_probability,
    documentId: sentenceNote.document_id,
    commentThreads: [],
    labels: [],
    attachments: [],
    richText: '',
    isDeleted: false,
    relevance: null,
    isHidden: false,
    isEditable: true,
    bucket: null
  };
};

export const normalizeSystemGeneratedSentenceNotesResponse = (
  response: apiTypes.FetchSystemGeneratedSentenceNotesResponse
): types.NormalizedSystemGeneratedSentenceNotesResponse => {
  return {
    sentenceNotes: response.sentence_notes.map(normalizeSystemGeneratedSentenceNote),
    total: response.total
  };
};
