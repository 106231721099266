import type { WorkflowData } from './useWorkflowData.types';
import type { WorkflowQueryHandlers } from './useWorkflowQueryHandlers.types';
import type { WorkflowProps } from '../Workflow.types';

import { QUERY_KEYS } from 'constants/QueryKeys';

import * as workflowsApi from 'shared/features/workflow/workflow.api';
import * as helpers from './useWorkflowQueries.helpers';

import { useQuery } from '@tanstack/react-query';

export const useWorkflowQueries = ({
  props,
  localState,
  onDocTasksFetchSuccess
}: {
  props: Pick<
    WorkflowProps,
    'document' | 'workflow_id' | 'sentenceNoteId' | 'workflow_task_order_ids'
  >;
  localState: WorkflowData['localState'];
  onDocTasksFetchSuccess: WorkflowQueryHandlers['handleDocumentTasksFetchSuccess'];
}) => {
  const fetchDocumentTasksQuery = useQuery<
    Awaited<ReturnType<typeof workflowsApi.api_fetchDocumentTasks>>,
    Error,
    ReturnType<ReturnType<typeof helpers.formatTasksResponse>>
  >({
    queryKey: [
      QUERY_KEYS.DOC_TASKS,
      {
        doc_id: props.document.id,
        workflow_id: props.workflow_id,
        sentence_note_id: props.sentenceNoteId
      }
    ],
    queryFn: async () => {
      const result = await workflowsApi.api_fetchDocumentTasks({
        doc_id: props.document.id,
        workflow_id: props.workflow_id,
        sentence_note_id: props.sentenceNoteId
      });

      const data = helpers.formatTasksResponse({
        props,
        localState
      })(result);

      onDocTasksFetchSuccess(data);

      return result;
    },
    select: helpers.formatTasksResponse({
      props,
      localState
    })
  });

  return {
    fetchDocumentTasksQuery
  };
};
