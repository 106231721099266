import type { TextMultiSelectProps } from '../TextMultiSelect.types';
import type { TextMultiSelectData } from './useTextMultiSelectData.types';

import { prepareValue } from './useTextMultiSelectData.helpers';
import { useMemo, useState } from 'react';

export const useTextMultiSelectData = ({
  props
}: {
  props: TextMultiSelectProps;
}): TextMultiSelectData => {
  const formattedData = useMemo(() => {
    return {
      value: prepareValue(props.value)
    };
  }, [props.value]);

  return {
    formattedData
  };
};
