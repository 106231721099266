import type { FC } from 'react';
import type { ActionCellProps } from './ActionCell.types';

import classnames from 'classnames';

import * as uiLib from '@compliance.ai/web-components';

import * as constants from '../../ManageBucketAccess.constants';
import * as shareBucketConstants from '../../../../ShareBucketModal.constants';

import { useActionCellData, useActionCellMutations } from './hooks';

import './_action-cell.scss';

export const ActionCell: FC<ActionCellProps> = props => {
  const { localState, localActions } = useActionCellData({ props });

  const mutations = useActionCellMutations({ props, localActions });

  if (props.row?.[constants.TABLE_KEYS.IS_GROUP_HEADER]) {
    return (
      <div
        className={classnames('action-cell__container', {
          'action-cell__container--is-group-header': true
        })}
      >
        &nbsp;
      </div>
    );
  }

  if (props.row?.[constants.TABLE_KEYS.IS_OWNER]) {
    return (
      <div className="action-cell__container">
        <uiLib.Typography>Owner</uiLib.Typography>
      </div>
    );
  }

  return (
    <div className="action-cell__container">
      <uiLib.Select
        type={uiLib.SELECT_TYPES.DEFAULT}
        options={shareBucketConstants.SHARE_BUCKET_ACCESS_LEVEL_OPTIONS}
        value={localState.accessLevel}
        onChange={mutations.updateBucketAccessLevel.mutate}
        isClearable={false}
        isSearchable={false}
        isLoading={mutations.updateBucketAccessLevel.isPending}
        isDisabled={!props.isBucketEditable || mutations.updateBucketAccessLevel.isPending}
      />

      <uiLib.TooltipDialog
        confirmButtonType={uiLib.BUTTON_TYPES.LINK_DELETE}
        onConfirm={
          mutations.deleteBucketAccessLevel.mutate as uiLib.TooltipDialogProps['onConfirm']
        }
        isDisabled={!props.isBucketEditable || mutations.deleteBucketAccessLevel.isPending}
      >
        <uiLib.ActionButton
          tooltip="Delete Access"
          tooltipType={uiLib.TOOLTIP_TYPES.PRIMARY}
          isDisabled={!props.isBucketEditable || mutations.deleteBucketAccessLevel.isPending}
        >
          <uiLib.IconDelete />
        </uiLib.ActionButton>
      </uiLib.TooltipDialog>
    </div>
  );
};
