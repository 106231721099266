import type { ExportProps } from '../Export.types';
import type { ExportData } from './useExportData.types';

import * as exportConstants from 'constants/Export';
import * as DocumentPropertiesConstants from 'constants/DocumentProperties';
import * as downloadsApi from 'shared/features/downloads/downloads.api';
import * as errorUtils from 'utils/errors';
import * as analyticsUtils from 'utils/analytics';
import * as downloadUtils from 'utils/downloads';
import * as uiLib from '@compliance.ai/web-components';
import * as documentsApi from 'shared/features/documents/documents.api';
import * as pdfUtils from 'utils/pdf';
import * as helpers from './useExportMutations.helpers';

import JSZip from 'jszip';
import pluralize from 'pluralize';

import { useMutation } from '@tanstack/react-query';
import { useUserReduxActions } from 'shared/features/user/hooks';

export const useExportMutations = ({
  props,
  localState,
  localActions,
  reduxState
}: {
  props: Pick<
    ExportProps,
    'annotationIDs' | 'documentIds' | 'onActionStart' | 'isAnnotationExport'
  >;
  localState: ExportData['localState'];
  localActions: ExportData['localActions'];
  reduxState: ExportData['reduxState'];
}) => {
  const userReduxActions = useUserReduxActions();

  const createDownload = useMutation<
    unknown,
    Error,
    [contentType: exportConstants.CONTENT_TYPES, columns: string[]]
  >({
    mutationFn: async ([contentType, columns]) => {
      props.onActionStart?.();

      const params = {
        columns: props.isAnnotationExport ? [...columns, 'annotations'] : columns,
        download_type: exportConstants.EXPORT_TYPES.DOCUMENT,
        doc_ids: props.documentIds,
        content_type: contentType,
        is_annotation_export: props.isAnnotationExport,
        is_single_file_export: columns.includes(
          DocumentPropertiesConstants.API_KEYS[DocumentPropertiesConstants.ANNOTATION_SINGLE_FILE]
        ),
        annotation_ids: props.annotationIDs
      };

      await downloadsApi.postDownloads(params);
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: (_, [contentType]) => {
      const updatedBulkExports = helpers.getUpdatedBulkExports({
        bulkExports: reduxState.bulkExports,
        downloadStartTime: localState.downloadStartTime,
        contentType: contentType,
        docCount: props.documentIds.length
      });

      userReduxActions.updateCurrentUser(reduxState.currentUser.email, {
        ...reduxState.currentUser,
        properties: {
          ...reduxState.currentUser.properties,
          bulk_export: updatedBulkExports
        }
      });

      localActions.setIsDownloadModalOpen(true);
      localActions.setDownloadStartTime(null);
    }
  });

  const exportPDFs = useMutation<
    {
      settledPromises: PromiseSettledResult<void>[];
      zipPDF: typeof JSZip;
    },
    Error
  >({
    mutationFn: async () => {
      const zipPDF = new JSZip();

      const settledPromises = await Promise.allSettled(
        localState.documents.map(document =>
          (async () => {
            const pdf: ArrayBuffer = await pdfUtils.fetchDocumentPDF(document);

            if (!pdf) {
              analyticsUtils.safe_analytics(
                'Dashboard – PDF unavailable',
                'Dashboard',
                'PDF unavailable',
                document.title
              );

              return;
            }

            analyticsUtils.safe_analytics(
              'Doc Action – Download document',
              'Doc Action',
              'Download document',
              document.title
            );

            if (props.documentIds.length === 1) {
              const filename = pdfUtils.generateFilename(document.title, document.id);
              const blob = new Blob([pdf], { type: exportConstants.CONTENT_TYPES.PDF });

              downloadUtils.initiateDownload(blob, filename);
            } else {
              zipPDF.file(pdfUtils.generateFilename(document.title, document.id), pdf, {
                binary: true
              });
            }
          })()
        )
      );

      return {
        settledPromises,
        zipPDF
      };
    },

    onSuccess: async ({ settledPromises, zipPDF }) => {
      localActions.setDownloadStartTime(null);

      const updatedBulkExports = helpers.getUpdatedBulkExports({
        bulkExports: reduxState.bulkExports,
        downloadStartTime: localState.downloadStartTime,
        contentType: localState.contentType as exportConstants.CONTENT_TYPES,
        docCount: props.documentIds.length
      });

      userReduxActions.updateCurrentUser(reduxState.currentUser.email, {
        ...reduxState.currentUser,
        properties: {
          ...reduxState.currentUser.properties,
          bulk_export: updatedBulkExports
        }
      });

      const failedCalls = settledPromises.filter(({ status }) => status === 'rejected');
      const successfulCalls = settledPromises.filter(({ status }) => status === 'fulfilled');

      if (failedCalls.length > 0) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.ERROR,
          title: 'Error',
          message: `${failedCalls.length} ${pluralize('document', failedCalls.length)} ${pluralize(
            'were',
            failedCalls.length
          )} not included because their PDFs are unavailable.`
        });
      }

      if (successfulCalls.length > 0) {
        const blob = await zipPDF.generateAsync({ type: 'blob' });
        const downloadDate = uiLib.formatDate(new Date(), { format: 'MMDDYYYY' });

        downloadUtils.initiateDownload(blob, `Compliance_ai_Export_${downloadDate}.zip`);
      }
    },

    onError: errorUtils.logReactQueryError
  });

  const exportHTML = useMutation<
    {
      settledPromises: PromiseSettledResult<void>[];
      zipHTML: typeof JSZip;
    },
    Error
  >({
    mutationFn: async () => {
      const zipHTML = new JSZip();

      const settledPromises = await Promise.allSettled(
        localState.documents.map(document =>
          (async () => {
            if (!document.has_sentences) return;

            const sentences: ArrayBuffer = await documentsApi.api_fetchSentences(
              [document.id],
              { use_obligation_version: false },
              exportConstants.CONTENT_TYPES.HTML
            );

            if (props.documentIds.length === 1) {
              const blob = new Blob([sentences], { type: exportConstants.CONTENT_TYPES.HTML });

              downloadUtils.initiateDownload(blob, document.title);
            } else {
              const downloadDate = uiLib.formatDate(new Date(), { format: 'MMDDYYYY' });

              zipHTML.file(`Compliance_ai_Export_${downloadDate}_${document.id}.html`, sentences);
            }
          })()
        )
      );

      return {
        settledPromises,
        zipHTML
      };
    },

    onSuccess: async ({ zipHTML }) => {
      localActions.setDownloadStartTime(null);

      const updatedBulkExports = helpers.getUpdatedBulkExports({
        bulkExports: reduxState.bulkExports,
        downloadStartTime: localState.downloadStartTime,
        contentType: localState.contentType as exportConstants.CONTENT_TYPES,
        docCount: props.documentIds.length
      });

      userReduxActions.updateCurrentUser(reduxState.currentUser.email, {
        ...reduxState.currentUser,
        properties: {
          ...reduxState.currentUser.properties,
          bulk_export: updatedBulkExports
        }
      });

      if (props.documentIds.length > 1) {
        const blob = await zipHTML.generateAsync({ type: 'blob' });

        downloadUtils.initiateDownload(blob, zipHTML.file?.name);
      }
    },

    onError: errorUtils.logReactQueryError
  });

  return {
    createDownload,
    exportPDFs,
    exportHTML,

    isLoading: createDownload.isPending || exportPDFs.isPending || exportHTML.isPending
  };
};
