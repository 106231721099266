import classnames from 'classnames';
import * as uiLib from '@compliance.ai/web-components';

import {
  useExportDropdownData,
  useExportDropdownHandlers,
  useExportDropdownQueries
} from './hooks';

import './_export-dropdown.scss';

export const ExportDropdown = ({
  enabled,
  documentIds,
  isLoading,
  onSelectContentType,
  isAnnotationExport,
  actionButtonType,
  onDocumentsFetchSuccess
}) => {
  const { localState, localActions, formattedData } = useExportDropdownData({
    props: { enabled, documentIds, isLoading, isAnnotationExport }
  });

  const queries = useExportDropdownQueries({
    props: {
      documentIds,
      onDocumentsFetchSuccess
    },
    localActions
  });

  const handlers = useExportDropdownHandlers({
    props: { onSelectContentType },
    localActions,
    formattedData,
    queries
  });

  return (
    <uiLib.Dropdown
      isOpen={localState.isDropdownOpen}
      onClose={handlers.handleDropdownToggle(false)}
      containerClassName="doc-action-wrapper"
      className="document-export-dropdown"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      triggerContent={
        <>
          <uiLib.ActionButton
            isRestricted={formattedData.isRestricted}
            isDisabled={formattedData.isDisabled}
            onClick={handlers.handleDropdownToggle(true)}
            onRestrictedClick={handlers.handleRestrictModalOpen}
            tooltip="Export"
            tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
            type={actionButtonType}
          >
            <uiLib.IconDownload />
          </uiLib.ActionButton>
        </>
      }
      dropdownContent={
        <div className="document-export-dropdown_content">
          {(isLoading || queries.fetchDocuments.isPending) && (
            <uiLib.Loader withoutBackground={false} />
          )}
          <div className="document-export-dropdown_content__header">
            <div className="document-export-dropdown_content__header-title">Download as...</div>

            <uiLib.IconClose
              className="document-export-dropdown_content__header-close-icon"
              onClick={handlers.handleDropdownToggle(false)}
              fontSize="small"
            />
          </div>

          <div className="document-export-dropdown_content__body">
            {formattedData.dropdownOptions.map(({ value, label }) => (
              <div
                className={classnames('document-export-dropdown_content__item', {
                  'document-export-dropdown_content__item--is-disabled': Boolean(
                    formattedData.isContentTypeDisabled[value]
                  )
                })}
                key={value}
                onClick={handlers.handleContentTypeSelect(value)}
              >
                {label}
              </div>
            ))}
          </div>
          {!isAnnotationExport && (
            <div className="document-export-dropdown_content__footer">
              {formattedData.footerText}
            </div>
          )}
        </div>
      }
    />
  );
};
