import type { TextMultiSelectProps } from './TextMultiSelect.types';
import type { SelectProps } from '@compliance.ai/web-components';

import * as uiLib from '@compliance.ai/web-components';

import { useTextMultiSelectHandlers, useTextMultiSelectData } from './hooks';
import { memo } from 'react';

export const TextMultiSelect = memo((props: TextMultiSelectProps) => {
  const { formattedData } = useTextMultiSelectData({ props });

  const handlers = useTextMultiSelectHandlers({ props });

  return (
    <uiLib.Select
      {...props}
      value={formattedData.value}
      isLoading={props.isLoading}
      onChange={props.onChange as SelectProps['onChange']}
      type={props.type || uiLib.SELECT_TYPES.DEFAULT}
      isMulti
      noOptionsMessage={inputValue => (inputValue ? 'No options' : 'Type to add option')}
      isCreatable
      isClearable
      onCreateOption={handlers.handleCreateOption}
    />
  );
});
