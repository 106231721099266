import type {
  BaseWidget,
  Config,
  Conjunction,
  Operator,
  Settings
} from 'react-awesome-query-builder';

import * as queryBuilder from 'react-awesome-query-builder';
import lodash from 'lodash';
import * as uiLib from '@compliance.ai/web-components';

import * as elements from './elements';
import { AutosuggestionSelect } from '../../../../../AutosuggestionSelect';
import { JurisdictionsSelect } from '../../../../../JurisdictionsSelect';
import { RegulationsSelect } from '../../../../../RegulationsSelect';
import { AuthorsSelect } from '../../../../../AuthorsSelect';
import { TopicsSelect } from '../../../../../TopicsSelect';
import { LabelsSelect } from '../../../../../LabelsSelect';
import { NewsAndPremiumContentSelect } from '../../../../../NewsAndPremiumContentSelect';
import { AgenciesSelect } from '../../../../../AgenciesSelect';
import { LanguagesSelect } from '../../../../../LanguagesSelect';
import { LegacyDocTypesSelect } from '../../../../../LegacyDocTypesSelect';
import { CategoriesSelect } from '../../../../../CategoriesSelect';
import { EitlLabelsSelect } from '../../../../../EitlLabelsSelect';
import { ConceptsSelect } from '../../../../../ConceptsSelect';
import { TextMultiSelect } from 'common/TextMultiSelect';

import * as helpers from './Query.config.helpers';

import * as relativeDatesConstants from 'constants/RelativeDates';
import * as advancedSearchConstants from 'constants/AdvancedSearch';

// Omitted formatters to make TS happy
export const QUERY_CONJUNCTION_OMITTED_CONFIG: Pick<Conjunction, 'sqlFormatConj' | 'mongoConj'> = {
  sqlFormatConj: () => '',
  mongoConj: ''
};

export enum SELECT_DATA_TYPES {
  STRING = 'string',
  NUMBER = 'number'
}

export const QUERY_CONJUNCTIONS_CONFIG: Config['conjunctions'] = {
  ...queryBuilder.BasicConfig.conjunctions,
  [advancedSearchConstants.QUERY_CONJUNCTIONS.AND]: {
    ...QUERY_CONJUNCTION_OMITTED_CONFIG,
    label: 'And',
    reversedConj: advancedSearchConstants.QUERY_CONJUNCTIONS.OR,
    formatConj: (children, conj, not) => {
      return children.size > 1
        ? (not ? 'NOT ' : '') + '(' + children.join(' AND ') + ')'
        : (not ? 'NOT (' : '') + children.first() + (not ? ')' : '');
    }
  },
  [advancedSearchConstants.QUERY_CONJUNCTIONS.OR]: {
    ...QUERY_CONJUNCTION_OMITTED_CONFIG,
    label: 'Or',
    reversedConj: advancedSearchConstants.QUERY_CONJUNCTIONS.OR,
    formatConj: (children, conj, not) => {
      return children.size > 1
        ? (not ? 'NOT ' : '') + '(' + children.join(' OR ') + ')'
        : (not ? 'NOT (' : '') + children.first() + (not ? ')' : '');
    }
  }
};

export const QUERY_OPERATORS_CONFIG: Config['operators'] = {
  ...queryBuilder.BasicConfig.operators,
  [advancedSearchConstants.QUERY_OPERATORS.EQUAL]: {
    ...queryBuilder.BasicConfig.operators[advancedSearchConstants.QUERY_OPERATORS.EQUAL],
    label: 'is',
    formatOp: (
      field,
      op,
      value,
      valueSrcs,
      valueTypes,
      operator,
      operatorOptions,
      isForDisplay
    ) => {
      if (valueTypes === 'boolean' && isForDisplay) {
        return value === 'No' ? `NOT ${field}` : `${field}`;
      }
      if (isForDisplay) {
        return `${field}`;
      }
      return `${field} ${operator?.labelForFormat} ${value}`;
    },
    labelForFormat: '='
  },
  [advancedSearchConstants.QUERY_OPERATORS.NOT_EQUAL]: {
    ...queryBuilder.BasicConfig.operators[advancedSearchConstants.QUERY_OPERATORS.NOT_EQUAL],
    label: 'is not',
    labelForFormat: '!=',
    formatOp: (
      field,
      op,
      value,
      valueSrcs,
      valueTypes,
      operator,
      operatorOptions,
      isForDisplay
    ) => {
      if (valueTypes === 'boolean' && isForDisplay) {
        return value === 'No' ? `${field}` : `NOT ${field}`;
      }
      if (isForDisplay) {
        return `${field}`;
      }
      return `${field} ${operator?.labelForFormat} ${value}`;
    }
  },
  [advancedSearchConstants.QUERY_OPERATORS.SELECT_EQUALS]: {
    ...queryBuilder.BasicConfig.operators[advancedSearchConstants.QUERY_OPERATORS.SELECT_EQUALS],
    label: 'is',
    labelForFormat: '=',
    formatOp: (field, op, value, valueSrc, valueType, operator, operatorOptions, isForDisplay) => {
      if (isForDisplay) return `${field}`;
      if (!value.length) return '';
      return `${field} ${operator?.labelForFormat} ${value}`;
    }
  },
  [advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS]: {
    ...queryBuilder.BasicConfig.operators[
      advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS
    ],
    label: 'Equals',
    labelForFormat: '=',
    formatOp: (field, op, values, valueSrc, valueType, operator, operatorOptions, isForDisplay) => {
      if (isForDisplay) return `${field}`;
      if (!values.length) return '';
      if (values.length === 1) return `${field} = ${values[0]}`;
      if (valueSrc === 'value') return `${field} IN [${(values as string[]).join(', ')}]`;
      return `${field} ${operator?.labelForFormat} ${values}`;
    }
  },
  [advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_NOT_EQUALS]: {
    ...queryBuilder.BasicConfig.operators[
      advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_NOT_EQUALS
    ],
    label: 'Not equals',
    labelForFormat: '!=',
    formatOp: (field, op, values, valueSrc, valueType, operator, operatorOptions, isForDisplay) => {
      if (isForDisplay) return `${field}`;
      if (!values.length) return '';
      if (values.length === 1) return `${field} != ${values[0]}`;
      if (valueSrc === 'value') return `NOT ${field} IN [${(values as string[]).join(', ')}]`;
      return `${field} ${operator?.labelForFormat} ${values}`;
    }
  },
  [advancedSearchConstants.QUERY_OPERATORS.TODAY]: {
    label: relativeDatesConstants.SINGLE_RELATIVE_DATES.TODAY,
    cardinality: 0,
    formatOp: (
      field,
      op,
      value,
      valueSrcs,
      valueTypes,
      operator,
      operatorOptions,
      isForDisplay
    ) => {
      return isForDisplay
        ? field
        : `${field} = "${relativeDatesConstants.SINGLE_RELATIVE_DATES.TODAY}"`;
    },
    jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY
  } as Operator,
  [advancedSearchConstants.QUERY_OPERATORS.YESTERDAY]: {
    label: relativeDatesConstants.SINGLE_RELATIVE_DATES.YESTERDAY,
    cardinality: 0,
    formatOp: (field, op, value, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
      return isForDisplay
        ? field
        : `${field} = "${relativeDatesConstants.SINGLE_RELATIVE_DATES.YESTERDAY}"`;
    },
    jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY
  } as Operator,
  [advancedSearchConstants.QUERY_OPERATORS.TOMORROW]: {
    label: relativeDatesConstants.SINGLE_RELATIVE_DATES.TOMORROW,
    cardinality: 0,
    formatOp: (field, op, value, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
      return isForDisplay
        ? field
        : `${field} = "${relativeDatesConstants.SINGLE_RELATIVE_DATES.TOMORROW}"`;
    },
    jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW
  } as Operator,
  [advancedSearchConstants.QUERY_OPERATORS.PAST]: {
    cardinality: 1,
    label: relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.PAST,
    formatOp: (field, op, value) => {
      return !!value ? `${field} <= "${value}"` : '';
    },
    jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST
  } as Operator,
  [advancedSearchConstants.QUERY_OPERATORS.NEXT]: {
    cardinality: 1,
    label: relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.NEXT,
    formatOp: (field, op, value) => {
      return !!value ? `${field} >= "${value}"` : '';
    },
    jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.NEXT
  } as Operator
};

export const QUERY_WIDGETS_CONFIG: Config['widgets'] = {
  ...queryBuilder.BasicConfig.widgets,
  [advancedSearchConstants.QUERY_INPUT_TYPES.TEXT_BASIC]: {
    ...queryBuilder.BasicConfig.widgets.text,
    formatValue: (val, _fieldDef, _wgtDef) => {
      if (
        advancedSearchConstants.QUOTES_ARRAY.includes(val[0]) &&
        advancedSearchConstants.QUOTES_ARRAY.includes(val[val.length - 1])
      ) {
        return val;
      }
      return `"${val}"`;
    }
  },
  [advancedSearchConstants.QUERY_INPUT_TYPES.BOOLEAN]: {
    ...queryBuilder.BasicConfig.widgets.boolean,
    labelYes: 'True',
    labelNo: 'False',
    defaultValue: true
  } as BaseWidget,
  [advancedSearchConstants.QUERY_INPUT_TYPES.DATE]: {
    ...queryBuilder.BasicConfig.widgets.date,
    dateFormat: uiLib.DATE_FORMATS.API_DATE,
    valueFormat: uiLib.DATE_FORMATS.API_DATE
  },
  [advancedSearchConstants.QUERY_INPUT_TYPES.JURISDICTION]: helpers.getSelectWidgetConfig(
    JurisdictionsSelect,
    {
      dataType: SELECT_DATA_TYPES.STRING
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.REGULATION]: helpers.getSelectWidgetConfig(
    RegulationsSelect,
    {
      dataType: SELECT_DATA_TYPES.NUMBER
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.AUTHOR]: helpers.getSelectWidgetConfig(AuthorsSelect, {
    dataType: SELECT_DATA_TYPES.NUMBER
  }),
  [advancedSearchConstants.QUERY_INPUT_TYPES.TOPIC]: helpers.getSelectWidgetConfig(TopicsSelect, {
    dataType: SELECT_DATA_TYPES.NUMBER
  }),
  [advancedSearchConstants.QUERY_INPUT_TYPES.LABEL]: helpers.getSelectWidgetConfig(LabelsSelect, {
    dataType: SELECT_DATA_TYPES.NUMBER
  }),
  [advancedSearchConstants.QUERY_INPUT_TYPES.EITL_LABEL]: helpers.getSelectWidgetConfig(
    EitlLabelsSelect,
    {
      dataType: SELECT_DATA_TYPES.NUMBER
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.NEWS_SOURCE]: helpers.getSelectWidgetConfig(
    NewsAndPremiumContentSelect,
    {
      dataType: SELECT_DATA_TYPES.STRING
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.AGENCY]: helpers.getSelectWidgetConfig(
    AgenciesSelect,
    {
      dataType: SELECT_DATA_TYPES.STRING
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.LANGUAGE]: helpers.getSelectWidgetConfig(
    LanguagesSelect,
    {
      isAlwaysSingle: true,
      dataType: SELECT_DATA_TYPES.NUMBER
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.AUTOSUGGESTION]: helpers.getSelectWidgetConfig(
    AutosuggestionSelect as Parameters<typeof helpers.getSelectWidgetConfig>[0],
    {
      dataType: SELECT_DATA_TYPES.NUMBER
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.LEGACY_DOC_TYPES]: helpers.getSelectWidgetConfig(
    LegacyDocTypesSelect as Parameters<typeof helpers.getSelectWidgetConfig>[0],
    {
      dataType: SELECT_DATA_TYPES.STRING
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.CATEGORIES]: helpers.getSelectWidgetConfig(
    CategoriesSelect as Parameters<typeof helpers.getSelectWidgetConfig>[0],
    {
      dataType: SELECT_DATA_TYPES.NUMBER
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.CONCEPTS]: helpers.getSelectWidgetConfig(
    ConceptsSelect as Parameters<typeof helpers.getSelectWidgetConfig>[0],
    {
      dataType: SELECT_DATA_TYPES.NUMBER
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.RELATIVE_DATES]: {
    jsType: 'string',
    valueSrc: 'value',
    valueLabel: 'Values',
    valuePlaceholder: 'Select Values',
    type: advancedSearchConstants.QUERY_INPUT_TYPES.RELATIVE_DATES,
    factory: elements.RelativeDatePicker as BaseWidget['factory']
  } as BaseWidget,
  [advancedSearchConstants.QUERY_INPUT_TYPES.MULTI_TEXT]: helpers.getSelectWidgetConfig(
    TextMultiSelect,
    {
      dataType: SELECT_DATA_TYPES.STRING
    }
  )
};

export const QUERY_TYPES_CONFIG: Config['types'] = {
  ...queryBuilder.BasicConfig.types,
  [advancedSearchConstants.QUERY_INPUT_TYPES.JURISDICTION]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.JURISDICTION
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.REGULATION]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.REGULATION
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.AUTHOR]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.AUTHOR
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.TOPIC]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.TOPIC
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.LABEL]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.LABEL
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.EITL_LABEL]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.EITL_LABEL
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.NEWS_SOURCE]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.NEWS_SOURCE
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.AGENCY]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.AGENCY
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.AUTOSUGGESTION]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.AUTOSUGGESTION
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.LEGACY_DOC_TYPES]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.LEGACY_DOC_TYPES
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.CATEGORIES]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.CATEGORIES
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.CONCEPTS]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.CONCEPTS
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES
    .LANGUAGE]: helpers.getSelectTypeConfig(advancedSearchConstants.QUERY_INPUT_TYPES.LANGUAGE, [
    advancedSearchConstants.QUERY_OPERATORS.SELECT_EQUALS
  ]),
  [advancedSearchConstants.QUERY_INPUT_TYPES.BOOLEAN]: {
    ...queryBuilder.BasicConfig.types.boolean,
    valueSources: ['value']
  },
  [advancedSearchConstants.QUERY_INPUT_TYPES.DATE]: lodash.merge(
    queryBuilder.BasicConfig.types.date,
    {
      ...queryBuilder.BasicConfig.types.date,
      widgets: {
        [advancedSearchConstants.QUERY_INPUT_TYPES.RELATIVE_DATES]: {
          widgetProps: {},
          operators: [
            relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST,
            relativeDatesConstants.REATIVE_DATE_OPERATORS.NEXT
          ]
        },
        [advancedSearchConstants.QUERY_INPUT_TYPES.DATE]: {
          opProps: {
            [advancedSearchConstants.QUERY_OPERATORS.EQUAL]: {
              label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.ON} (=)`
            },
            [advancedSearchConstants.QUERY_OPERATORS.LESS]: {
              label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.BEFORE} (<)`
            },
            [advancedSearchConstants.QUERY_OPERATORS.LESS_OR_EQUAL]: {
              label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.ON_OR_BEFORE} (<=)`
            },
            [advancedSearchConstants.QUERY_OPERATORS.GRATER]: {
              label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.AFTER} (>)`
            },
            [advancedSearchConstants.QUERY_OPERATORS.GREATER_OR_EQUAL]: {
              label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.ON_OR_AFTER} (>=)`
            }
          }
        }
      },
      operators: [
        advancedSearchConstants.QUERY_OPERATORS.GREATER_OR_EQUAL,
        advancedSearchConstants.QUERY_OPERATORS.LESS_OR_EQUAL,
        advancedSearchConstants.QUERY_OPERATORS.EQUAL,
        advancedSearchConstants.QUERY_OPERATORS.NOT_EQUAL,
        advancedSearchConstants.QUERY_OPERATORS.LESS,
        advancedSearchConstants.QUERY_OPERATORS.GRATER,
        advancedSearchConstants.QUERY_OPERATORS.TODAY,
        advancedSearchConstants.QUERY_OPERATORS.YESTERDAY,
        advancedSearchConstants.QUERY_OPERATORS.TOMORROW
      ]
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.TEXT_BASIC]: lodash.merge(
    queryBuilder.BasicConfig.types.text,
    {
      operators: [
        advancedSearchConstants.QUERY_OPERATORS.EQUAL,
        advancedSearchConstants.QUERY_OPERATORS.NOT_EQUAL
      ]
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.NUMBER]: lodash.merge(
    queryBuilder.BasicConfig.types.number,
    {
      operators: [
        advancedSearchConstants.QUERY_OPERATORS.EQUAL,
        advancedSearchConstants.QUERY_OPERATORS.NOT_EQUAL
      ]
    }
  ),
  [advancedSearchConstants.QUERY_INPUT_TYPES.MULTI_TEXT]: helpers.getSelectTypeConfig(
    advancedSearchConstants.QUERY_INPUT_TYPES.MULTI_TEXT
  )
};

export const QUERY_SETTINGS_CONFIG: Config['settings'] & {
  mustLeaveAtLeastOneRule: boolean;
} = {
  ...queryBuilder.BasicConfig.settings,
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  funcLabel: 'Function',
  fieldPlaceholder: 'Select field',
  funcPlaceholder: 'Select function',
  operatorPlaceholder: 'Select operator',
  deleteLabel: undefined,
  addGroupLabel: '+',
  addRuleLabel: '+',
  delGroupLabel: undefined,
  notLabel: 'NOT',
  valueSourcesPopupTitle: 'Select value source',
  removeRuleConfirmOptions: {
    title: 'Do you want to delete the filter?',
    okText: 'Yes',
    okType: 'danger'
  },
  removeGroupConfirmOptions: {
    title: 'Do you want to delete the filter group?',
    okText: 'Yes',
    okType: 'danger'
  },
  valueSourcesInfo: {
    value: {
      label: 'Value'
    }
  },
  canLeaveEmptyGroup: false,
  mustLeaveAtLeastOneRule: false,
  renderButton: elements.Button as Settings['renderButton'],
  renderConjs: elements.ConjunctionGroup as Settings['renderConjs'],
  renderField: (elements.QueryFieldSelectWithTooltips as unknown) as Settings['renderField'],
  renderOperator: (elements.QueryFieldSelect as unknown) as Settings['renderOperator']
};
