import type { ActionCellProps } from '../ActionCell.types';
import type { ActionCellData } from './useActionCellData.types';

import type * as uiLib from '@compliance.ai/web-components';
import type * as rulesTypes from 'shared/features/rules/rules.types';

import * as rulesApi from 'shared/features/rules/rules.api';
import * as errorUtils from 'utils/errors';

import * as constants from '../../../../../ShareBucketModal.constants';
import * as tableConstants from '../../../ManageBucketAccess.constants';
import { QUERY_KEYS, COMPLEX_QUERY_KEY_GETTERS, QUERY_SECTIONS } from 'constants/QueryKeys';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useActionCellMutations = ({
  props,
  localActions
}: {
  props: Pick<ActionCellProps, 'bucketId' | 'row' | 'onActionSuccess'>;
  localActions: ActionCellData['localActions'];
}) => {
  const queryClient = useQueryClient();

  const updateBucketAccessLevel = useMutation<
    void,
    Error,
    Parameters<Required<uiLib.SelectProps>['onChange']>[0]
  >({
    mutationKey: [QUERY_KEYS.RULES],
    mutationFn: async option => {
      const rule = props.row[tableConstants.TABLE_KEYS.RULE] as rulesTypes.RuleFromResponse;
      const accessLevel = option as uiLib.SelectOption;
      const isEditAccess = accessLevel.value === constants.SHARE_BUCKET_ACCESS_LEVEL.EDIT;
      const isViewAccess =
        isEditAccess || accessLevel.value === constants.SHARE_BUCKET_ACCESS_LEVEL.VIEW;

      await rulesApi.updateRule(rule.id, {
        view_access: isViewAccess,
        edit_access: isEditAccess
      });

      localActions.setAccessLevel(accessLevel);
    },
    onSuccess: () => {
      props.onActionSuccess();
      queryClient.invalidateQueries({
        queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.RULES][QUERY_SECTIONS.BUCKETS_ACCESS_TABLE](
          props.bucketId
        )
      });
    },
    onError: errorUtils.logReactQueryError
  });

  const deleteBucketAccessLevel = useMutation<void, Error, void>({
    mutationKey: [QUERY_KEYS.RULES],
    mutationFn: async () => {
      const rule = props.row[tableConstants.TABLE_KEYS.RULE] as rulesTypes.RuleFromResponse;

      await rulesApi.deleteRule(rule.id);
    },
    onSuccess: () => {
      props.onActionSuccess();
      queryClient.invalidateQueries({
        queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.RULES][QUERY_SECTIONS.BUCKETS_ACCESS_TABLE](
          props.bucketId
        )
      });
    },
    onError: errorUtils.logReactQueryError
  });

  return {
    updateBucketAccessLevel,
    deleteBucketAccessLevel
  };
};
