import type { AnnotationPanelHandlers } from './useAnnotationPanelHandlers.types';
import type { AnnotationPanelData } from './useAnnotationPanelData.types';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useSentenceNotesReduxActions } from 'shared/features/sentenceNotes/hooks';

export const useAnnotationPanelHandlers = ({
  reduxState
}: {
  reduxState: AnnotationPanelData['reduxState'];
}): AnnotationPanelHandlers => {
  const rightPanelReduxActions = useRightPanelReduxActions();
  const sentenceNotesReduxActions = useSentenceNotesReduxActions();

  const handleOverlayOpenOnMount: AnnotationPanelHandlers['handleOverlayOpenOnMount'] = () => {
    if (reduxState.isAuthenticated) {
      rightPanelReduxActions.openOverlay(rightPanelConstants.OVERLAY_TYPES.HTML);
    }
  };

  const handleOverlayCloseOnUnmount: AnnotationPanelHandlers['handleOverlayCloseOnUnmount'] = () => {
    if (!reduxState.shouldReopenOverlay) {
      rightPanelReduxActions.closeOverlay();
    }
  };

  const handleStateClearOnUnmount: AnnotationPanelHandlers['handleStateClearOnUnmount'] = () => {
    sentenceNotesReduxActions.clearSentenceNotesReducer();
  };

  return {
    handleOverlayOpenOnMount,
    handleOverlayCloseOnUnmount,
    handleStateClearOnUnmount
  };
};
