import type { PlainTextData } from './usePlainTextData.types';
import type { PlainTextHandlers } from './usePlainTextHandlers.types';

import { useQueries } from '@tanstack/react-query';
import { ASYNC_DATA_CONFIG } from '../PlainText.constants';
import { logReactQueryError } from 'utils/errors';

export const usePlainTextQueries = ({
  formattedData,
  onSuccess
}: {
  formattedData: PlainTextData['formattedData'];
  onSuccess: PlainTextHandlers['handleQuerySuccess'];
}) => {
  return useQueries({
    queries: Object.entries(ASYNC_DATA_CONFIG).map(([key, config]) => {
      const advancedSearchKey = key as keyof typeof ASYNC_DATA_CONFIG;

      const { jsonLogicTreeAsyncVarsValues } = formattedData.parsedQuery;

      return {
        enabled: Boolean(jsonLogicTreeAsyncVarsValues[advancedSearchKey].length),
        queryFn: async () => {
          try {
            const data = await config.onFetch(jsonLogicTreeAsyncVarsValues[advancedSearchKey]);

            onSuccess(advancedSearchKey)(data);

            return data;
          } catch (error) {
            logReactQueryError(error as Error);
            throw error;
          }
        },
        queryKey: config.queryKeyGetter(jsonLogicTreeAsyncVarsValues[advancedSearchKey]),
        select: config.onSelect
      };
    })
  });
};
