import type { SelectValue } from '@compliance.ai/web-components';

export const prepareValue = (value?: SelectValue) => {
  if (Array.isArray(value)) {
    return value.map(item => (typeof item === 'string' ? { value: item, label: item } : item));
  }
  if (typeof value === 'string') {
    return [{ value, label: value }];
  }
  return [];
};
