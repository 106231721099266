import type { FC } from 'react';
import type { AddLabelsModalProps } from './AddLabelsModal.types';

import * as uiLib from '@compliance.ai/web-components';
import { LabelsSelect } from '../../../../LabelsSelect';

import './_add-labels-modal.scss';

import {
  useAddLabelsModalData,
  useAddLabelsModalMutations,
  useAddLabelsModalHandlers
} from './hooks';

export const AddLabelsModal: FC<AddLabelsModalProps> = ({
  documentIds,
  onClose,
  onLabelCreation,
  onActionStart
}) => {
  const { localState, localActions } = useAddLabelsModalData();

  const mutations = useAddLabelsModalMutations({
    props: {
      documentIds,
      onClose,
      onActionStart
    },
    localState
  });

  const handlers = useAddLabelsModalHandlers({
    localActions
  });

  return (
    <uiLib.Modal
      title={`Add label to ${documentIds.length} documents`}
      isOpen
      withFooter
      primaryButtonText="Add Label"
      secondaryButtonText="Cancel"
      onSubmit={mutations.addLabels.mutate}
      onClose={onClose}
      isPrimaryButtonDisabled={mutations.addLabels.isPending}
      classNames={{
        container: 'add-labels-modal',
        content: 'add-labels-modal__container'
      }}
    >
      <>
        {mutations.addLabels.isPending && <uiLib.Loader withoutBackground={false} />}
        <uiLib.LabeledField
          label="Search and select label"
          className="add-labels-modal__input-container"
        >
          <LabelsSelect
            isCreatable
            isAsync
            isMulti
            placeholder="Search label"
            value={localState.selectedLabelIds}
            onChange={handlers.handleLabelsChange}
            onCreateOption={onLabelCreation}
            isEditable
          />
        </uiLib.LabeledField>
      </>
    </uiLib.Modal>
  );
};
