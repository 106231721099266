import type { AnnotationPanelData } from './useAnnotationPanelData.types';
import type { GlobalState } from 'shared/reducers';

import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as workflowSelectors from 'shared/features/workflow/workflow.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import { useSelector } from 'react-redux';

export const useAnnotationPanelData = (): AnnotationPanelData => {
  const reduxState = useSelector<GlobalState, AnnotationPanelData['reduxState']>(state => ({
    selectedSentenceNoteId: rightPanelSelectors.getSentenceNoteId(state),
    isAuthenticated: authSelectors.getUserAuthenticatedState(state as never),
    isAddingDocumentsToWorkflow: workflowSelectors.getAddingDocumentsToWorkflowFlag(state),
    shouldReopenOverlay: rightPanelSelectors.getOverlayReopenToggle(state)
  }));

  return {
    reduxState
  };
};
