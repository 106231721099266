import type { DiffProps } from './Diff.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as viewActions from 'shared/features/view/view.actions';

import { useDiffData, useDiffHandlers, useDiffQueries } from './hooks';

import './_diff.scss';

export const Diff: FC<DiffProps> = ({ documentIds, isEnabled, queryOptions, onActionStart }) => {
  const { formattedData } = useDiffData();

  const queries = useDiffQueries({
    props: {
      documentIds,
      isEnabled,
      onActionStart,
      queryOptions
    }
  });

  const handlers = useDiffHandlers({
    queries
  });

  return (
    <uiLib.ActionButton
      isDisabled={
        !isEnabled || queries.fetchDocuments.isPending || queries.fetchDocuments.data?.isDisabled
      }
      onClick={handlers.handleButtonClick}
      isRestricted={formattedData.isRestricted}
      onRestrictedClick={() =>
        viewActions.showRestrictModal(null, 'Compare text changes between 2 documents')
      }
      tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
      isLoading={queries.fetchDocuments.isFetching}
      tooltip={
        <span className="diff__tooltip">
          Select 2 documents with the
          <uiLib.IconDiff className=" diff__tooltip-icon" />
          icon to see the difference between them.
        </span>
      }
    >
      <uiLib.IconDiff />
    </uiLib.ActionButton>
  );
};

Diff.defaultProps = {
  isEnabled: true,
  documentIds: []
};
