import type { SentenceNoteProps } from './SentenceNote.types';
import type { FC } from 'react';

import classnames from 'classnames';

import * as elements from './elements';
import * as uiLib from '@compliance.ai/web-components';

import {
  useSentenceNoteData,
  useSentenceNoteHandlers,
  useSentenceNoteLifecycle,
  useSentenceNoteMutations
} from './hooks';

import './_sentence-notes.scss';

export const SentenceNote: FC<SentenceNoteProps> = ({ document, sentenceNote }) => {
  const { refs, reduxState, formattedData } = useSentenceNoteData({
    sentenceNote
  });

  const handlers = useSentenceNoteHandlers({
    props: {
      document,
      sentenceNote
    },
    refs,
    reduxState
  });

  const mutations = useSentenceNoteMutations({
    props: {
      document,
      sentenceNote
    },
    reduxState
  });

  useSentenceNoteLifecycle({
    onMount: handlers.handleSentenceSelection
  });

  return (
    <div
      ref={refs.sentenceNote}
      className={classnames({
        'sentence-note': true,
        'sentence-note--is-hidden': sentenceNote.isObligation && sentenceNote.isHidden,
        'sentence-note--is-selected': formattedData.isSelected,
        'sentence-note--is-user-custom': !sentenceNote.isSystemObligation,
        'sentence-note--is-likely-obligation': formattedData.isLikelyObligation
      })}
    >
      {sentenceNote.isObligation && sentenceNote.isHidden ? (
        <div className="sentence-note__hidden-content">
          <uiLib.ActionButton
            type={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
            onClick={mutations.toggleSentenceNote.mutate}
          >
            <uiLib.IconMenu className="sentence-note__unhide-icon" />
          </uiLib.ActionButton>
        </div>
      ) : (
        <>
          {mutations.toggleSentenceNote.isPending && (
            <uiLib.Loader isContrast withoutBackground={false} />
          )}
          <div className="sentence-note__sentence-container">
            <div className="sentence-note__content">
              <elements.SentenceNoteText
                sentencesText={sentenceNote.sentencesText}
                bucket={sentenceNote.bucket}
                isObligation={sentenceNote.isObligation}
                onClick={handlers.handleSentenceNoteClick}
              />
              <elements.ExpandButton
                isExpanded={reduxState.isExpanded}
                isDisabled={mutations.isLoading}
                onClick={mutations.toggleSentenceNote.mutate}
              />
            </div>
            {sentenceNote.isObligation && (
              <elements.ObligationToolbar
                documentId={document.id}
                sentenceNote={sentenceNote}
                onRelevancyChange={handlers.handleRelevancyChange}
                isReadOnly={
                  mutations.isLoading ||
                  (!sentenceNote.isSystemObligation && !formattedData.isEditable)
                }
              />
            )}
          </div>

          <div
            className={classnames({
              'sentence-note__bottom-container': true,
              'sentence-note__bottom-container--is-expanded': reduxState.isExpanded
            })}
          >
            {reduxState.isExpanded && sentenceNote.id && (
              <elements.SentenceNoteContent
                document={document}
                isEditable={formattedData.isEditable}
                id={sentenceNote.id}
              />
            )}
            <elements.SentenceNoteDeleteButton
              isSystemObligation={sentenceNote.isSystemObligation}
              isEditable={formattedData.isEditable}
              isLoading={mutations.deleteSentenceNote.isPending}
              isObligation={sentenceNote.isObligation}
              onDelete={mutations.deleteSentenceNote.mutate}
            />
          </div>
        </>
      )}
    </div>
  );
};
