import type { LabelFromResponse } from 'shared/features/labels/labels.api.types';
import type { LabelOption, LabelsSelectProps } from '../LabelsSelect.types';
import type { LabelsSelectLocalState } from './useLabelsSelectData.types';
export const isOption = (value?: LabelFromResponse['id'] | LabelOption | null) =>
  value && typeof value === 'object' && 'value' in value;

export const getLabelIds = (value: LabelsSelectProps['value']) => {
  if (!value) {
    return [];
  }

  return value.map(valueOrOption => {
    if (isOption(valueOrOption)) {
      return (valueOrOption as LabelOption).value;
    }

    return valueOrOption as number;
  });
};

export const getInitialValue = (
  value: LabelsSelectProps['value']
): LabelsSelectLocalState['value'] => {
  if (!value || !isOption(value[0])) {
    return null;
  }
  return value as LabelOption[];
};
