import type { StoreDispatch } from 'store';

import * as actions from '../../rightPanel.actions';

import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useRightPanelReduxActions = () => {
  const dispatch = useDispatch<StoreDispatch>();

  const openRightPanel = useCallback(
    (...args: Parameters<typeof actions.openRightPanel>) => {
      dispatch(actions.openRightPanel(...args));
    },
    [dispatch]
  );

  const closeRightPanel = useCallback(
    (...args: Parameters<typeof actions.closeRightPanel>) => {
      dispatch(actions.closeRightPanel(...args));
    },
    [dispatch]
  );

  const changeDocumentDetailsActiveTab = useCallback(
    (...args: Parameters<typeof actions.changeDocumentDetailsActiveTab>) => {
      dispatch(actions.changeDocumentDetailsActiveTab(...args));
    },
    [dispatch]
  );

  const triggerRightPanelLabelsRefresh = useCallback(
    (...args: Parameters<typeof actions.triggerRightPanelLabelsRefresh>) => {
      dispatch(actions.triggerRightPanelLabelsRefresh(...args));
    },
    [dispatch]
  );

  const triggerRightPanelTasksRefresh = useCallback(
    (...args: Parameters<typeof actions.triggerRightPanelTasksRefresh>) => {
      dispatch(actions.triggerRightPanelTasksRefresh(...args));
    },
    [dispatch]
  );

  const triggerOverlayRefresh = useCallback(
    (...args: Parameters<typeof actions.triggerOverlayRefresh>) => {
      dispatch(actions.triggerOverlayRefresh(...args));
    },
    [dispatch]
  );

  const openOverlay = useCallback(
    (...args: Parameters<typeof actions.openOverlay>) => {
      dispatch(actions.openOverlay(...args));
    },
    [dispatch]
  );

  const setOverlayType = useCallback(
    (...args: Parameters<typeof actions.openOverlay>) => {
      dispatch(actions.setOverlayType(...args));
    },
    [dispatch]
  );

  const closeOverlay = useCallback(
    (...args: Parameters<typeof actions.closeOverlay>) => {
      dispatch(actions.closeOverlay(...args));
    },
    [dispatch]
  );

  const toggleOverlay = useCallback(
    (...args: Parameters<typeof actions.toggleOverlay>) => {
      dispatch(actions.toggleOverlay(...args));
    },
    [dispatch]
  );

  const toggleShouldReopenOverlay = useCallback(
    (...args: Parameters<typeof actions.toggleShouldReopenOverlay>) => {
      dispatch(actions.toggleShouldReopenOverlay(...args));
    },
    [dispatch]
  );

  const changeSentenceNoteId = useCallback(
    (...args: Parameters<typeof actions.changeSentenceNoteId>) => {
      dispatch(actions.changeSentenceNoteId(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      openRightPanel,
      closeRightPanel,
      changeDocumentDetailsActiveTab,
      changeSentenceNoteId,
      triggerRightPanelLabelsRefresh,
      triggerRightPanelTasksRefresh,
      triggerOverlayRefresh,
      openOverlay,
      closeOverlay,
      toggleOverlay,
      toggleShouldReopenOverlay,
      setOverlayType
    }),
    [
      openRightPanel,
      closeRightPanel,
      changeDocumentDetailsActiveTab,
      changeSentenceNoteId,
      triggerRightPanelLabelsRefresh,
      triggerRightPanelTasksRefresh,
      triggerOverlayRefresh,
      openOverlay,
      closeOverlay,
      toggleOverlay,
      toggleShouldReopenOverlay,
      setOverlayType
    ]
  );
};
