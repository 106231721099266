import * as localStorageConstants from '../constants/LocalStorage';
import * as errorUtils from 'utils/errors';
import * as uiLib from '@compliance.ai/web-components';

export const safelySetLocalStorageData = (key, data) =>
  uiLib.safelySetLocalStorageData({ key, data, onError: e => errorUtils.logError(e) });

export const safelyParseLocalStorageArray = arrStringified => {
  try {
    const arr = JSON.parse(arrStringified);

    return Array.isArray(arr) ? arr : [];
  } catch (error) {
    return [];
  }
};

export const safelyGetLocalStorageArray = key => {
  const arrStringified = localStorage.getItem(key);
  return safelyParseLocalStorageArray(arrStringified);
};

export const removeQueryCache = () => {
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEYS.REACT_QUERY_OFFLINE_CACHE);
};

/**
 * TODO Remove this handler once there won't be any users with the old cache
 **/
export const removeDeprecatedQueryCache = () => {
  localStorage.removeItem(
    localStorageConstants.LOCAL_STORAGE_KEYS.DEPRECATED_REACT_QUERY_OFFLINE_CACHE
  );
};

export const getAnonymousUserFilter = () => {
  const stringifiedFilter = localStorage.getItem(
    localStorageConstants.LOCAL_STORAGE_KEYS.ANONYMOUS_FILTER
  );

  try {
    if (!stringifiedFilter) {
      return {};
    }
    return JSON.parse(stringifiedFilter);
  } catch {
    console.error("Couldn't parse anonymous user filter");
    return {};
  }
};

export const setAnonymousUserFilter = filterParams => {
  safelySetLocalStorageData(
    localStorageConstants.LOCAL_STORAGE_KEYS.ANONYMOUS_FILTER,
    JSON.stringify(filterParams)
  );
};

export const removeAnonymousUserFilter = () => {
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEYS.ANONYMOUS_FILTER);
};

export const setSentActivityAnalytics = bool => {
  safelySetLocalStorageData(localStorageConstants.LOCAL_STORAGE_KEYS.SENT_ACTIVITY_ANALYTICS, bool);
};

export const getSentActivityAnalytics = () => {
  return (
    localStorage.getItem(localStorageConstants.LOCAL_STORAGE_KEYS.SENT_ACTIVITY_ANALYTICS) || ''
  );
};

export const removeSentActivityAnalytics = () => {
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEYS.SENT_ACTIVITY_ANALYTICS);
};

export const setEnteredCorporateEmail = email => {
  safelySetLocalStorageData(localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_EMAIL, email);
};

export const getEnteredCorporateEmail = () => {
  return localStorage.getItem(localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_EMAIL) || '';
};

export const removeEnteredCorporateEmail = () => {
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_EMAIL);
};

export const setCognitoPoolInformationId = id => {
  safelySetLocalStorageData(
    localStorageConstants.LOCAL_STORAGE_KEYS.COGNITO_POOL_INFORMATION_ID,
    id
  );
};

export const getCognitoPoolInformationId = () => {
  return localStorage.getItem(localStorageConstants.LOCAL_STORAGE_KEYS.COGNITO_POOL_INFORMATION_ID);
};

export const removeCognitoPoolInformationId = () => {
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEYS.COGNITO_POOL_INFORMATION_ID);
};

export const setCorporateLoginApprovedAppUrl = url => {
  safelySetLocalStorageData(
    localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_APPROVED_APP_URl,
    url
  );
};

export const getCorporateLoginApprovedAppUrl = () => {
  return localStorage.getItem(
    localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_APPROVED_APP_URl
  );
};

export const removeCorporateLoginApprovedAppUrl = () => {
  localStorage.removeItem(
    localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_APPROVED_APP_URl
  );
};

export const setCorporateLoginJWTToken = token => {
  safelySetLocalStorageData(
    localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_JWT_TOKEN,
    token
  );
};

export const getCorporateLoginJWTToken = () => {
  return localStorage.getItem(localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_JWT_TOKEN);
};

export const removeCorporateLoginJWTToken = () => {
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEYS.CORPORATE_LOGIN_JWT_TOKEN);
};
