import type { AddRelatedDocumentsModalProps } from './AddRelatedDocumentsModal.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common/index';
import * as constants from './AddRelatedDocumentsModal.constants';

import {
  useAddRelatedDocumentsModalData,
  useAddRelatedDocumentsModalHandlers,
  useAddRelatedDocumentsModalMutations
} from './hooks';

import './_add-related-documents-modal.scss';

export const AddRelatedDocumentsModal: FC<AddRelatedDocumentsModalProps> = ({
  documentId,
  onClose
}) => {
  const { localState, localActions, reduxState } = useAddRelatedDocumentsModalData();

  const handlers = useAddRelatedDocumentsModalHandlers({
    localActions
  });

  const mutations = useAddRelatedDocumentsModalMutations({
    props: {
      documentId,
      onClose
    },
    localState
  });

  return (
    <uiLib.Modal
      isOpen
      title="Search and Add Related Docs"
      onClose={onClose}
      primaryButtonText="Add Related Docs"
      withFooter
      onSubmit={mutations.addRelatedDocuments.mutate}
      className="add-related-documents-modal"
      childContainerClassName="add-related-documents-modal__children-container"
      footerClassName="add-related-documents-modal__footer"
      isPrimaryButtonDisabled={
        mutations.addRelatedDocuments.isPending || !localState.relatedDocIds.length
      }
    >
      <div className="add-related-documents-modal__content">
        <uiLib.ErrorBoundary>
          <common.DocumentsFilters
            onSubmit={handlers.handleFiltersChange}
            shouldDisableDropdownsPortal
            classNames={{
              container: 'add-related-documents-modal__filters'
            }}
          />
          <common.DocumentsTable
            shouldUseSavedTableSettings
            tableSettingsKey={constants.TABLE_SETTINGS_KEY}
            requestParams={localState.requestParams}
            onSelectedDocumentsChange={handlers.handleSelectedDocumentsChange}
            shouldDisableDropdownsPortal
            classNames={{
              container: 'add-related-documents-modal__table-outer-container',
              tableContainer: 'add-related-documents-modal__table-container',
              table: 'add-related-documents-modal__table'
            }}
          />
        </uiLib.ErrorBoundary>
      </div>
    </uiLib.Modal>
  );
};
