import type { DocumentDetailsRightPanelConfigData } from './useDocumentDetailsRightPanelConfigData.types';
import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';
import * as helpers from './useDocumentDetailsRightPanelConfigQueries.helpers';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as documentsApiV2 from 'shared/features/documents/documents.apiV2';
import * as workflowApi from 'shared/features/workflow/workflow.api';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks';

export const useDocumentDetailsRightPanelConfigQueries = ({
  reduxState
}: {
  reduxState: DocumentDetailsRightPanelConfigData['reduxState'];
}) => {
  const documentsReduxActions = useDocumentsReduxActions();

  const fetchDocumentDetails = useQuery<
    Parameters<typeof helpers.formatData>[0],
    Error,
    ReturnType<typeof helpers.formatData>
  >({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
      queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
    ](Number(reduxState.rightPanel.documentId)),
    queryFn: async () => {
      try {
        const [documentDetailsResponse] = await Promise.all([
          documentsReduxActions.fetchDocumentDetails(reduxState.rightPanel.documentId),
          documentsReduxActions.fetchRelatedDocumentCount(reduxState.rightPanel.documentId),
          documentsReduxActions.fetchDocumentCommentsCount(reduxState.rightPanel.documentId)
        ]);

        const document = documentDetailsResponse[0].document as DocumentFromResponse;

        return {
          document
        };
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    enabled: Boolean(
      reduxState.rightPanel.type === rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS &&
        reduxState.rightPanel.documentId
    ),
    refetchOnWindowFocus: false,
    retry: false,
    select: helpers.formatData
  });

  const fetchDocumentVersionsCount = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENT_VERSIONS_COUNT][
      queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
    ]({
      documentId: Number(reduxState.rightPanel.documentId),
      documentVersionDocketId: fetchDocumentDetails.data?.document?.document_version_docket_id,
      dockets: fetchDocumentDetails.data?.document?.dockets
    }),
    queryFn: async () => {
      try {
        const [versionsCount, docketsCount] = await Promise.all([
          documentsApiV2.fetchDocumentVersionsCount(
            fetchDocumentDetails.data?.document?.document_version_docket_id ?? null
          ),
          documentsApiV2.fetchDocumentDocketsCount(
            fetchDocumentDetails.data?.document?.dockets.map(docket => docket.docket_id) ?? []
          )
        ]);

        return {
          versionsCount,
          docketsCount
        };
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    enabled: Boolean(
      reduxState.rightPanel.type === rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS &&
        reduxState.rightPanel.documentId
    ),
    refetchOnWindowFocus: false,
    retry: false
  });

  const fetchDocumentTasksStatusBadgeType = useQuery<
    Parameters<typeof helpers.formatDocumentTasksStatus>[0],
    Error,
    ReturnType<typeof helpers.formatDocumentTasksStatus>
  >({
    queryKey: [queryKeys.QUERY_KEYS.DOC_TASKS, reduxState.rightPanel.documentId],
    queryFn: async () => {
      try {
        const tasks = await workflowApi.api_fetchDocumentTasks(
          {
            doc_id: reduxState.rightPanel.documentId,
            assignee_id: reduxState.currentUserId,
            exclusive_fields: ['results.task_status', 'results.deadline']
          },
          { shouldDisableQueryParamsArrayFormatting: true }
        );
        return tasks.results;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    enabled: Boolean(
      reduxState.rightPanel.type === rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS &&
        reduxState.rightPanel.documentId &&
        reduxState.currentUserId
    ),
    refetchOnWindowFocus: false,
    retry: false,
    select: helpers.formatDocumentTasksStatus
  });

  return {
    fetchDocumentDetails,
    fetchDocumentVersionsCount,
    fetchDocumentTasksStatusBadgeType
  };
};
