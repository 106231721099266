import type { LabelsSelectData } from './useLabelsSelectData.types';
import type { LabelsSelectLocalState } from './useLabelsSelectData.types';
import type { LabelsSelectProps } from '../LabelsSelect.types';

import * as helpers from './useLabelsSelectData.helpers';

import { useMemo, useState } from 'react';

export const useLabelsSelectData = (props: Pick<LabelsSelectProps, 'value'>): LabelsSelectData => {
  const [value, setValue] = useState<LabelsSelectLocalState['value']>(
    helpers.getInitialValue(props.value)
  );

  const localState: LabelsSelectData['localState'] = {
    value
  };

  const localActions: LabelsSelectData['localActions'] = useMemo(
    () => ({
      setValue
    }),
    []
  );

  const formattedData: LabelsSelectData['formattedData'] = useMemo(() => {
    const labelIds = helpers.getLabelIds(props.value);

    return {
      labelIds,
      isSelectedLabelsValueOptions: Boolean(helpers.isOption(props.value?.[0]))
    };
  }, [props.value]);

  return {
    localState,
    localActions,
    formattedData
  };
};
