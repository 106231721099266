import type { CreateLabelModalProps } from './CreateLabelModal.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { BucketSelect } from '../BucketSelect';
import { LabelTypeSelect } from 'common';

import './_create-label-modal.scss';

import {
  useCreateLabelModalData,
  useCreateLabelModalHandlers,
  useCreateLabelModalMutations
} from './hooks';

export const CreateLabelModal: FC<CreateLabelModalProps> = ({
  onClose,
  initialLabelName,
  onLabelCreate
}) => {
  const { localState, localActions, formattedData } = useCreateLabelModalData({
    props: {
      initialLabelName
    }
  });

  const handlers = useCreateLabelModalHandlers({
    localActions
  });

  const mutations = useCreateLabelModalMutations({
    props: {
      onClose,
      onLabelCreate
    },
    localState,
    formattedData
  });

  return (
    <uiLib.Modal
      title="Add label"
      isOpen
      onClose={onClose}
      withFooter
      primaryButtonType={uiLib.BUTTON_TYPES.PRIMARY}
      primaryButtonText="Add"
      secondaryButtonType={uiLib.BUTTON_TYPES.LINK}
      secondaryButtonText="Close"
      isPrimaryButtonDisabled={
        formattedData.isCreateLabelButtonDisabled || mutations.createLabel.isPending
      }
      onSubmit={mutations.createLabel.mutateAsync}
      classNames={{
        container: 'create-label-modal',
        content: 'create-label-modal__container'
      }}
    >
      <>
        {mutations.createLabel.isPending && <uiLib.Loader withoutBackground={false} />}
        <uiLib.LabeledField label="Add to Collection">
          <BucketSelect value={localState.bucket} onChange={handlers.handleBucketChange} />
        </uiLib.LabeledField>
        <uiLib.LabeledField label="Label Name">
          <uiLib.TextField
            value={localState.labelName}
            placeholder="Enter a new label name"
            onChange={handlers.handleLabelNameChange}
          />
        </uiLib.LabeledField>
        <uiLib.LabeledField label="Label Description">
          <uiLib.TextField
            value={localState.labelDescription}
            placeholder="Enter a new label Description"
            onChange={handlers.handleLabelDescriptionChange}
          />
        </uiLib.LabeledField>
        <uiLib.LabeledField label="Label Type">
          <LabelTypeSelect value={localState.labelType} onChange={handlers.handleLabelTypeChange} />
        </uiLib.LabeledField>
        <uiLib.LabeledField label="Label External ID">
          <uiLib.TextField
            value={localState.labelExternalId}
            onChange={handlers.handleLabelExternalIdChange}
            placeholder="Enter a new label External ID"
          />
        </uiLib.LabeledField>
      </>
    </uiLib.Modal>
  );
};
