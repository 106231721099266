import type { AddRelatedDocumentAlertsModalProps } from './AddRelatedDocumentAlertsModal.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common/index';
import * as relatedDocumentsConstants from 'constants/RelatedDocuments';

import {
  useAddRelatedDocumentAlertsModalData,
  useAddRelatedDocumentAlertsModalHandlers,
  useAddRelatedDocumentAlertsModalMutations
} from './hooks';

import './_add-related-document-alerts-modal.scss';

export const AddRelatedDocumentAlertsModal: FC<AddRelatedDocumentAlertsModalProps> = ({
  documentId,
  maxSelectableAlertsCount,
  selectedAlertIds,
  onClose
}) => {
  const { localState, localActions, reduxState } = useAddRelatedDocumentAlertsModalData();

  const handlers = useAddRelatedDocumentAlertsModalHandlers({
    localActions
  });

  const mutations = useAddRelatedDocumentAlertsModalMutations({
    props: {
      documentId,
      onClose
    },
    localState
  });

  const primaryButton = (
    <uiLib.Button
      type={uiLib.BUTTON_TYPES.PRIMARY}
      onClick={mutations.addAlerts.mutate}
      isDisabled={
        reduxState.isFetchingAlerts || mutations.addAlerts.isPending || !localState.alertIds.length
      }
      className="add-related-document-alerts-modal__footer-primary-button"
    >
      Add
    </uiLib.Button>
  );

  return (
    <uiLib.Modal
      isOpen
      title="Select Alerts to Relate Documents"
      onClose={onClose}
      className="add-related-document-alerts-modal"
      childContainerClassName="add-related-document-alerts-modal__children-container"
    >
      <div className="add-related-document-alerts-modal__content">
        <uiLib.ErrorBoundary>
          <common.AlertsTable
            selectedAlertIds={selectedAlertIds}
            maxSelectableAlertsCount={maxSelectableAlertsCount}
            shouldShowSelectMaxRowsButton={false}
            onSelectedAlertsChange={handlers.handleSelectedAlertsChange}
            classNames={{
              container: 'add-related-document-alerts-modal__table-outer-container',
              tableContainer: 'add-related-document-alerts-modal__table-container',
              table: 'add-related-document-alerts-modal__table'
            }}
          />

          <div className="add-related-document-alerts-modal__footer">
            <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={onClose}>
              Cancel
            </uiLib.Button>

            {maxSelectableAlertsCount ? (
              <uiLib.Tooltip
                title={relatedDocumentsConstants.RELATED_DOCUMENT_ALERTS_TOOLTIP_TITLE.replace(
                  /MAX_RELATED_DOCUMENT_ALERTS_COUNT/g,
                  String(maxSelectableAlertsCount)
                )}
                type={uiLib.TOOLTIP_TYPES.SECONDARY}
                placement={uiLib.TOOLTIP_POSITION.TOP}
              >
                {primaryButton}
              </uiLib.Tooltip>
            ) : (
              primaryButton
            )}
          </div>
        </uiLib.ErrorBoundary>
      </div>
    </uiLib.Modal>
  );
};
