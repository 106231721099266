import * as workflowsApi from 'shared/features/workflow/workflow.api';
import { QUERY_KEYS } from 'constants/QueryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useTaskAttachmentsAnnotationMutations = () => {
  const queryClient = useQueryClient();

  const addDocumentTaskAttachmentsMutation = useMutation<
    Awaited<ReturnType<typeof workflowsApi.addTaskAttachment>>,
    Error,
    Parameters<typeof workflowsApi.addTaskAttachment>
  >({
    mutationFn: variables => workflowsApi.addTaskAttachment(...variables),
    mutationKey: [QUERY_KEYS.DOC_TASKS],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] })
  });

  const deleteDocumentTaskAttachmentsMutation = useMutation<
    Awaited<ReturnType<typeof workflowsApi.deleteTaskAttachment>>,
    Error,
    Parameters<typeof workflowsApi.deleteTaskAttachment>
  >({
    mutationFn: variables => workflowsApi.deleteTaskAttachment(...variables),
    mutationKey: [QUERY_KEYS.DOC_TASKS],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_TASKS] })
  });

  return {
    addDocumentTaskAttachmentsMutation,
    deleteDocumentTaskAttachmentsMutation
  };
};
