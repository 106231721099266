import { REDUCER_NAME } from './rightPanel.constants';
import { createSelector } from '@reduxjs/toolkit';

import * as rightPanelConstants from './rightPanel.constants';
import * as userSelectors from '../user/user.selectors';
import * as authSelectors from '../auth/auth.selectors';
import * as env from '../../config';
import * as autoSummaries from 'utils/sumup100cleanedjson';
import * as userRoleConstants from 'constants/UserRoles';

import queryString from 'utils/query-string';

export const getRightPanelReducer = createSelector(
  state => state,
  state => state[REDUCER_NAME]
);

export const getRightPanelOpenState = createSelector(
  getRightPanelReducer,
  rightPanelReducer => rightPanelReducer.isOpen
);
export const getRightPanelIsClosable = createSelector(getRightPanelReducer, rightPanelReducer => {
  return rightPanelReducer.isClosable;
});
export const getRightPanelType = createSelector(
  getRightPanelReducer,
  rightPanelReducer => rightPanelReducer.type
);
export const getRightPanelProps = createSelector(
  getRightPanelReducer,
  rightPanelReducer => rightPanelReducer.props
);
export const getRightPanelOverlayQueryParamsFlag = createSelector(
  getRightPanelProps,
  rightPanelProps => rightPanelProps.shouldOpenOverlayWithoutQueryParams
);
export const getRightPanelOverlayCloseFlag = createSelector(
  getRightPanelProps,
  rightPanelProps => rightPanelProps.shouldCloseRightPanelOnOverlayClose
);
export const getRightPanelDocumentId = createSelector(
  getRightPanelProps,
  rightPanelReducer => rightPanelReducer.documentId
);
export const getDocumentDetailsPanelData = createSelector(
  getRightPanelReducer,
  rightPanelReducer => rightPanelReducer.documentDetails
);
export const getDocumentDetailsActiveTabKey = createSelector(
  getDocumentDetailsPanelData,
  documentDetails => documentDetails.activeTabKey
);
export const isDiffView = createSelector(
  getDocumentDetailsActiveTabKey,
  activeTabKey => activeTabKey === rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DIFF
);
export const isSummaryPage = createSelector(
  getDocumentDetailsActiveTabKey,
  activeTabKey => activeTabKey === rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS
);
export const getDocumentDetailsExpandedRelatedDocsType = createSelector(
  getDocumentDetailsPanelData,
  documentDetails => documentDetails.expandedRelatedDocsType
);
export const getRightPanelLabelsRefreshTrigger = createSelector(
  getDocumentDetailsPanelData,
  documentDetails => documentDetails.labelsRefreshTrigger
);
export const getRightPanelTasksRefreshTrigger = createSelector(
  getDocumentDetailsPanelData,
  documentDetails => documentDetails.tasksRefreshTrigger
);
export const getDiffOverlayData = createSelector(getRightPanelProps, rightPanelProps => ({
  docV1: rightPanelProps.diffData?.docV1,
  docV2: rightPanelProps.diffData?.docV2
}));
export const getDiffOverlayDocs = createSelector(getDiffOverlayData, diffData => {
  if (diffData.docV1 && diffData.docV2) {
    return [diffData.docV1, diffData.docV2];
  }

  return [];
});

export const getSentenceNoteId = createSelector(getRightPanelProps, rightPanelProps => {
  return rightPanelProps.sentenceNoteId;
});

export const getFirstSentenceId = createSelector(getRightPanelProps, rightPanelProps => {
  return rightPanelProps.firstSentenceId;
});

export const getLastSentenceId = createSelector(getRightPanelProps, rightPanelProps => {
  return rightPanelProps.lastSentenceId;
});

export const getOverlayData = createSelector(
  getRightPanelReducer,
  rightPanelReducer => rightPanelReducer.overlay
);
export const getOverlayType = createSelector(getOverlayData, overlayData => overlayData.type);
export const getOverlayOpenState = createSelector(
  getOverlayData,
  overlayData => overlayData.isOpen
);
export const getOverlayRefreshTrigger = createSelector(
  getOverlayData,
  overlay => overlay.refreshTrigger
);
export const getOverlayReopenToggle = createSelector(
  getOverlayData,
  overlay => overlay.shouldReopen
);

export const getDocumentPDFUrl = createSelector(
  [getRightPanelDocumentId, userSelectors.getCurrentUserRoles, authSelectors.getJWTToken],
  (documentId, userRoles, JWTToken) => {
    const shouldShowAutoSummary =
      autoSummaries.autoSummaries[documentId] && userRoles.includes(userRoleConstants.POWER_USER);

    const accessToken = JWTToken || env.apiKey;

    if (shouldShowAutoSummary) {
      const queryParams = queryString.stringify({
        pdf_type: 'highlighted',
        access_token: accessToken
      });

      return `${env.apiUrl}/document_pdf/${documentId}?${queryParams}`;
    }

    const queryParamsWithoutPDFType = queryString.stringify({
      access_token: accessToken
    });

    return `${env.apiUrl}/document_pdf/${documentId}?${queryParamsWithoutPDFType}`;
  }
);
