import { createSelector } from '@reduxjs/toolkit';
import { alphabetically } from 'utils/sort';
import { mapToConceptsGroupOption, getSortedConceptIds } from './concepts.helpers';

export const getConceptsReadyStatus = state => state.concepts.isReady;
export const getConceptsReducer = state => state.concepts;

export const getConcepts = state => getConceptsReducer(state)?.concepts ?? [];
export const getMetaConcepts = state => getConceptsReducer(state)?.metaConcepts ?? [];

export const getConceptsList = createSelector(getConcepts, concepts =>
  Object.entries(concepts)
    .map(([key, value]) => ({ ...value, id: Number(key) }))
    .sort(alphabetically)
);

export const getConceptsNamesToIdsMap = createSelector(getConceptsList, concepts => {
  return concepts.reduce(
    (conceptNames, concept) => ({
      ...conceptNames,
      [concept.name]: concept.id
    }),
    {}
  );
});

export const getConceptsIdsToNamesMap = createSelector(getConceptsList, concepts => {
  return concepts.reduce(
    (conceptNames, concept) => ({
      ...conceptNames,
      [concept.id]: concept.name
    }),
    {}
  );
});

export const getMetaConceptsList = createSelector(
  getMetaConcepts,
  getConceptsList,
  (metaConcepts, conceptsList) =>
    Object.entries(metaConcepts)
      .map(([key, value]) => ({
        ...value,
        concepts: getSortedConceptIds(value.concepts, conceptsList),
        id: Number(key)
      }))
      .sort(alphabetically)
);

export const getConceptOptions = state => {
  const conceptsList = getConceptsList(state);

  return conceptsList.map(concept => ({
    value: concept.id,
    label: concept.name
  }));
};

export const getConceptOptionsByIDs = conceptIds =>
  createSelector(getConceptOptions, conceptOptions => {
    return conceptOptions.filter(option => conceptIds.includes(option.value));
  });

export const getConceptsGroupedOptions = state => {
  const conceptsList = getConceptsList(state);
  const metaConceptsList = getMetaConceptsList(state);

  return metaConceptsList.map(mapToConceptsGroupOption(conceptsList));
};

export const getConceptsGroupedOptionsByIds = conceptIds =>
  createSelector(getConceptsList, getMetaConceptsList, (conceptsList, metaConceptsList) => {
    const filteredConcepts = conceptsList.filter(concept => conceptIds.includes(concept.id));
    const mappedConcepts = metaConceptsList.map(mapToConceptsGroupOption(filteredConcepts));

    return mappedConcepts.filter(groupedConcepts => groupedConcepts.options.length);
  });

export const getConceptsOptionsWithMappedMetaNamesByIds = conceptIds =>
  createSelector(getConceptsGroupedOptionsByIds(conceptIds), groupedConcepts => {
    return groupedConcepts.flatMap(({ label, options }) => {
      return options.map(option => ({
        ...option,
        label: `${label}: ${option.label}`
      }));
    });
  });
