import type { DocumentDetailsRightPanelConfigHandlers } from './useDocumentDetailsRightPanelConfigHandlers.types';
import type { DocumentDetailsRightPanelConfigData } from './useDocumentDetailsRightPanelConfigData.types';

import * as analytics from 'utils/analytics';

import { useViewReduxActions } from 'shared/features/view/hooks';
import { useObligationsReduxActions } from 'shared/features/obligations/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useDocumentDetailsRightPanelConfigQueries } from './useDocumentDetailsRightPanelConfigQueries';
import {
  DOCUMENT_DETAILS_TAB_KEY,
  OVERLAY_TYPES
} from 'shared/features/rightPanel/rightPanel.constants';

export const useDocumentDetailsRightPanelConfigHandlers = ({
  queries,
  reduxState
}: {
  queries: ReturnType<typeof useDocumentDetailsRightPanelConfigQueries>;
  reduxState: DocumentDetailsRightPanelConfigData['reduxState'];
}): DocumentDetailsRightPanelConfigHandlers => {
  const viewReduxActions = useViewReduxActions();
  const obligationsReduxActions = useObligationsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleRestrictedTabClick: DocumentDetailsRightPanelConfigHandlers['handleRestrictedTabClick'] = title => () => {
    analytics.safe_analytics('default', 'Non-auth', 'Open restrict modal', title);
    viewReduxActions.showRestrictModal('', title, '');
  };

  const handleTabClick: DocumentDetailsRightPanelConfigHandlers['handleTabClick'] = ({
    tab,
    tabKey
  }) => () => {
    obligationsReduxActions.deselectSentences();
    rightPanelReduxActions.changeDocumentDetailsActiveTab({
      key: tabKey
    });
    if (
      reduxState.rightPanel.shouldReopenOverlay &&
      [
        DOCUMENT_DETAILS_TAB_KEY.AUTO_SUMMARY,
        DOCUMENT_DETAILS_TAB_KEY.DETAILS,
        DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS,
        DOCUMENT_DETAILS_TAB_KEY.TASKS,
        DOCUMENT_DETAILS_TAB_KEY.VERSIONS,
        DOCUMENT_DETAILS_TAB_KEY.EXPERT_CONNECTION
      ].includes(tabKey)
    ) {
      rightPanelReduxActions.openOverlay(OVERLAY_TYPES.PDF);
    }

    analytics.safe_analytics(
      'Doc Details – ' + `View ${tab.label}`,
      'Doc Details',
      `View ${tab.label}`,
      queries.fetchDocumentDetails.data?.document?.title
    );
  };

  return {
    handleRestrictedTabClick,
    handleTabClick
  };
};
