import type { DocumentTimelineHandlers } from './useDocumentTimelineHandlers.types';
import type { DocumentTimelineData } from './useDocumentTimelineData.types';

import * as analytics from 'utils/analytics';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import { useCallback } from 'react';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useDocumentTimelineHandlers = ({
  localState,
  localActions,
  reduxState
}: {
  localState: DocumentTimelineData['localState'];
  localActions: DocumentTimelineData['localActions'];
  reduxState: DocumentTimelineData['reduxState'];
}): DocumentTimelineHandlers => {
  const documentsReduxActions = useDocumentsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleDiffSelection: DocumentTimelineHandlers['handleDiffSelection'] = documentTimelineItem => () => {
    const isDocumentSelected = Boolean(
      reduxState.documentsToDiff.find(({ id }) => id === documentTimelineItem.document.id)
    );

    let updatedDocuments;
    let documentTitle;

    if (isDocumentSelected) {
      updatedDocuments = reduxState.documentsToDiff.filter(
        ({ id }) => id !== documentTimelineItem.document.id
      );
      documentTitle = updatedDocuments[0]?.title;
    } else {
      updatedDocuments = [...reduxState.documentsToDiff, documentTimelineItem.document];
      documentTitle = documentTimelineItem.document.title;
    }

    analytics.safe_analytics(
      'Doc Details-View Changes',
      'Doc Details',
      'View Changes',
      documentTitle
    );

    documentsReduxActions.addDocsToDiff(updatedDocuments);

    localActions.setOverlayType(
      updatedDocuments.length >= 2
        ? rightPanelConstants.OVERLAY_TYPES.DIFF
        : rightPanelConstants.OVERLAY_TYPES.PDF
    );
  };

  const handleOverlayOpenOnMount: DocumentTimelineHandlers['handleOverlayOpenOnMount'] = () => {
    rightPanelReduxActions.openOverlay(localState.overlayType);
  };

  const handleOverlayChange: DocumentTimelineHandlers['handleOverlayChange'] = useCallback(() => {
    if (reduxState.isOverlayOpen && reduxState.overlayType !== localState.overlayType) {
      rightPanelReduxActions.openOverlay(localState.overlayType);
    }
  }, [
    localState.overlayType,
    reduxState.isOverlayOpen,
    reduxState.overlayType,
    rightPanelReduxActions
  ]);

  const handleUnmount: DocumentTimelineHandlers['handleUnmount'] = () => {
    documentsReduxActions.addDocsToDiff([]);

    if (!reduxState.shouldReopenOverlay) {
      rightPanelReduxActions.closeOverlay();
    }
  };

  return {
    handleDiffSelection,
    handleOverlayOpenOnMount,
    handleOverlayChange,
    handleUnmount
  };
};
