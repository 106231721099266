import type { SentenceNoteProps } from '../SentenceNote.types';
import type { SentenceNoteData } from './useSentenceNoteData.types';

import * as errorUtils from 'utils/errors';

import { useMutation } from '@tanstack/react-query';
import { useObligationsReduxActions } from 'shared/features/obligations/hooks';
import { useSentenceNotesReduxActions } from 'shared/features/sentenceNotes/hooks';

export const useSentenceNoteMutations = ({
  props,
  reduxState
}: {
  props: Pick<SentenceNoteProps, 'document' | 'sentenceNote'>;
  reduxState: SentenceNoteData['reduxState'];
}) => {
  const obligationsReduxActions = useObligationsReduxActions();
  const sentenceNotesReduxActions = useSentenceNotesReduxActions();

  const deleteSentenceNote = useMutation<void, Error, void>({
    mutationFn: async () => {
      obligationsReduxActions.deselectSentences();

      if (props.sentenceNote.id) {
        await sentenceNotesReduxActions.deleteSentenceNote(props.sentenceNote.id);
      }
    },
    onError: errorUtils.logReactQueryError
  });

  const toggleSentenceNote = useMutation<void, Error, unknown>({
    mutationFn: async () => {
      if (!props.sentenceNote.id) {
        const createdSentenceNote = await sentenceNotesReduxActions.createSentenceNote({
          params: {
            document_id: props.document.id,
            first_sentence_id: props.sentenceNote.firstSentenceId,
            last_sentence_id: props.sentenceNote.lastSentenceId,
            left_offset: props.sentenceNote.leftOffset,
            right_offset: props.sentenceNote.rightOffset,
            sentences_text: props.sentenceNote.sentencesText,
            is_obligation: true,
            is_system_obligation: true,
            relevance: null,
            obligation_probability:
              'obligationProbability' in props.sentenceNote
                ? props.sentenceNote.obligationProbability
                : null
          }
        });

        sentenceNotesReduxActions.toggleSentenceNote({
          id: createdSentenceNote.id,
          isExpanded: true
        });
      } else {
        sentenceNotesReduxActions.toggleSentenceNote({
          id: props.sentenceNote.id,
          isExpanded: !reduxState.isExpanded
        });
      }

      if (props.sentenceNote.isHidden) {
        await sentenceNotesReduxActions.modifySentenceNote({
          id: props.sentenceNote.id,
          params: {
            hidden: false
          }
        });
      }
    },
    onError: errorUtils.logReactQueryError
  });

  return {
    deleteSentenceNote,
    toggleSentenceNote,

    isLoading: deleteSentenceNote.isPending || toggleSentenceNote.isPending
  };
};
