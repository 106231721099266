export const SHARED_SAVED_SEARCH_PERMISSIONS = {
  EDIT: 'edit',
  VIEW: 'view'
};

export const SAVED_SEARCH_PERMISSIONS = {
  EDIT: 'edit',
  SHARE: 'share',
  DELETE: 'delete',
  RSS: 'rss',
  RENAME: 'rename'
};

export enum API_NOTIFICATION_TYPES {
  DOCUMENT = 'document',
  SENTENCE = 'sentence',
  ALL_ALERTS = 'all'
}
