import * as localStorageConstants from 'constants/LocalStorage';

import { QueryClient } from '@tanstack/react-query';
import { createIDBPersister } from 'utils/reactQuery';

export const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24 // 24 hours
    }
  }
});

export const QUERY_CLIENT_PERSISTER = createIDBPersister({
  key: localStorageConstants.LOCAL_STORAGE_KEYS.REACT_QUERY_OFFLINE_CACHE
});
