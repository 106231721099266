import type { RelatedDocumentsPanelProps } from '../RelatedDocumentsPanel.types';

import * as documentsApi from 'shared/features/documents/documents.apiV2';
import * as errorUtils from 'utils/errors';
import * as queryKeys from 'constants/QueryKeys';
import * as helpers from './useRelatedDocumentsPanelQueries.helpers';
import * as constants from '../RelatedDocumentsPanel.constants';

import { useQuery } from '@tanstack/react-query';
import { usePermissions } from 'utils/hooks';

export const useRelatedDocumentsPanelQueries = ({
  props
}: {
  props: Pick<RelatedDocumentsPanelProps, 'originalDocID' | 'relatedDocuments'>;
}) => {
  const permissions = usePermissions();

  const fetchRelatedAlerts = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.RELATED_ALERTS][
      queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
    ](Number(props.originalDocID)),
    queryFn: async () => {
      try {
        const data = await documentsApi.fetchOrganizationRelatedDocumentAlerts(props.originalDocID);
        return data;
      } catch (error) {
        errorUtils.logReactQueryError(error as Error);
        throw error;
      }
    },
    initialData: constants.EMPTY_RELATED_ALERTS_RESPONSE,
    select: helpers.formatLists({
      relatedDocuments: props.relatedDocuments,
      permissions: permissions
    }),
    retry: false,
    refetchOnWindowFocus: false
  });

  return {
    fetchRelatedAlerts
  };
};
