import type { DocumentsTableData } from './useDocumentsTableData.types';
import type { DocumentsTableHandlers } from './useDocumentsTableHandlers.types';
import type { DocumentsTableProps } from '../DocumentsTable.types';
import type { RelatedDocsTableSettings } from 'shared/features/user/user.types';

import * as constants from '../DocumentsTable.constants';
import * as uiLib from '@compliance.ai/web-components';
import * as dataHelpers from './useDocumentsTableData.helpers';
import * as errorUtils from 'utils/errors';
import * as elements from '../elements';
import * as userConstants from 'shared/features/user/user.constants';
import * as filterConstants from 'constants/PrimaryFilter';
import * as paginationUtils from 'utils/pagination-utils';

import lodash from 'lodash';

import { useCallback } from 'react';
import { useDocumentsApi } from 'shared/features/documents/hooks';
import { useUserReduxActions } from 'shared/features/user/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeepCompareMemoize } from 'utils/hooks';

export const useDocumentsTableHandlers = ({
  props,
  prevProps,
  localState,
  localActions,
  reduxState
}: {
  props: Pick<
    DocumentsTableProps,
    | 'requestParams'
    | 'onSelectedDocumentsChange'
    | 'shouldUseSavedTableSettings'
    | 'tableSettingsKey'
    | 'rowsCountOptions'
    | 'shouldUseCustomOffsets'
  >;
  prevProps: DocumentsTableData['prevProps'];
  localState: DocumentsTableData['localState'];
  localActions: DocumentsTableData['localActions'];
  reduxState: DocumentsTableData['reduxState'];
}): DocumentsTableHandlers => {
  const userReduxActions = useUserReduxActions();
  const documentsApi = useDocumentsApi();
  const queryParams = uiLib.useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = uiLib.usePreviousValue(location);

  const prevOffset = uiLib.usePreviousValue(localState.tableParams.offset);
  const prevCustomOffsets = uiLib.usePreviousValue(localState.customOffsets);

  const handleDocumentsFetch: DocumentsTableHandlers['handleDocumentsFetch'] = useCallback(
    async params => {
      try {
        if (reduxState.isAuthenticated && !reduxState.areCurrentUserTableSettingsReady) {
          return {
            results: [],
            count: 0
          };
        }

        let requestParams = { ...props.requestParams };

        let offset = params[uiLib.TABLE_PARAMS.OFFSET];
        let limit = params[uiLib.TABLE_PARAMS.LIMIT];

        if (props.shouldUseCustomOffsets) {
          const customLimit =
            offset > 0
              ? limit
              : localState.limitToDisplay || (localState.defaultOffset as number) % limit || limit;

          if (prevOffset === 0 && localState.limitToDisplay) {
            offset = localState.limitToDisplay;
          }

          const page = paginationUtils.getPageByLimitAndOffset({
            offset: localState.limitToDisplay || offset,
            limit: limit
          });
          const localStatePage = paginationUtils.getPageByLimitAndOffset({
            offset: prevOffset,
            limit: customLimit
          });
          const defaultPage = paginationUtils.getPageByLimitAndOffset({
            offset: localState.defaultOffset,
            limit: limit
          });

          const offsetParams = paginationUtils.getOffsetParams({
            page,
            limit: limit,
            localStatePage,
            localStateLimit: customLimit,
            documentsOffsets:
              page > defaultPage && page < localStatePage
                ? prevCustomOffsets
                : localState.customOffsets,
            isPreviousDocs: page < defaultPage
          });

          if (page < localStatePage && page !== defaultPage) {
            requestParams = { ...requestParams, ...dataHelpers.formatCustomOffsets(offsetParams) };
          } else if (page > localStatePage && page !== defaultPage) {
            requestParams = {
              ...requestParams,
              ...dataHelpers.formatCustomOffsets(localState.customOffsets || {})
            };
          }

          localActions.setLimitToDisplay(limit === customLimit ? null : customLimit);
          limit = customLimit;
          offset = localState.defaultOffset === null ? 20 : (page - defaultPage + 1) * limit;
        } else {
          localActions.setLimitToDisplay(null);
        }

        const response = await documentsApi.fetchDocuments({
          ...requestParams,
          [filterConstants.SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: localState.sort.sortType,
          [filterConstants.SUPPORTED_QUERY_PARAMS.ORDER]: props.shouldUseCustomOffsets
            ? undefined
            : localState.sort.sortOrder,
          [filterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]: String(limit),
          [filterConstants.SUPPORTED_QUERY_PARAMS.OFFSET]: String(offset),
          [filterConstants.SUPPORTED_QUERY_PARAMS.INCLUDE_USER_PROPERTIES]: true,
          [filterConstants.SUPPORTED_QUERY_PARAMS.EXCLUSIVE_FIELDS]:
            filterConstants.DOCUMENT_EXLUSIVE_FIELDS
        });

        if (props.shouldUseCustomOffsets) {
          localActions.setCustomOffsets(
            response.offsets as DocumentsTableData['localState']['customOffsets']
          );
          const newOffset = paginationUtils.getStartDateOffset({ offsets: response.offsets });
          if (localState.defaultOffset === null) {
            localActions.setDefaultOffset(newOffset);
          }
          if (localState.isInitialRequest || (prevOffset === 0 && newOffset !== offset)) {
            localActions.setTableParams({
              limit: params[uiLib.TABLE_PARAMS.LIMIT],
              offset: newOffset
            });
          }
        }

        localActions.setAllDocumentsCount(response.count);

        return {
          results: response.documents,
          count: response.count
        };
      } catch (e) {
        errorUtils.logError(e as Error);

        return {
          results: [],
          count: 0
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([
      documentsApi,
      localActions,
      localState.customOffsets,
      localState.defaultOffset,
      localState.limitToDisplay,
      localState.sort.sortOrder,
      localState.sort.sortType,
      prevOffset,
      props.requestParams,
      props.shouldUseCustomOffsets,
      reduxState.areCurrentUserTableSettingsReady,
      reduxState.isAuthenticated,
      localState.isInitialRequest
    ])
  );

  const handleDocumentsIdsFetch: DocumentsTableHandlers['handleDocumentsIdsFetch'] = useCallback(async () => {
    try {
      if (reduxState.isAuthenticated && !reduxState.areCurrentUserTableSettingsReady) {
        return [];
      }

      const response = await documentsApi.fetchDocumentIds(
        {
          ...props.requestParams,
          [filterConstants.SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: localState.sort.sortType,
          [filterConstants.SUPPORTED_QUERY_PARAMS.ORDER]: localState.sort.sortOrder,
          [filterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]: String(constants.SELECTED_DOCS_MAX_COUNT),
          [filterConstants.SUPPORTED_QUERY_PARAMS.OFFSET]: String(constants.DEFAULT_OFFSET)
        },
        props.shouldUseCustomOffsets
      );

      return response.map(Number);
    } catch (e) {
      errorUtils.logError(e as Error);

      return [];
    }
  }, [
    documentsApi,
    localState.sort.sortOrder,
    localState.sort.sortType,
    props.requestParams,
    props.shouldUseCustomOffsets,
    reduxState.areCurrentUserTableSettingsReady,
    reduxState.isAuthenticated
  ]);

  const handleSelectedDocumentsChange: DocumentsTableHandlers['handleSelectedDocumentsChange'] = ids => {
    localActions.setSelectedDocsIds(ids.map(Number));

    props.onSelectedDocumentsChange?.(ids.map(Number));
  };

  const handleRouteChange: DocumentsTableHandlers['handleRouteChange'] = useCallback(() => {
    if (prevLocation.pathname !== location.pathname) {
      localActions.setTableDeselectTrigger(trigger => !trigger);
      localActions.setSelectedDocsIds([]);

      props.onSelectedDocumentsChange?.([]);
    }
  }, [localActions, location.pathname, prevLocation.pathname, props]);

  const handleTableSettingsChange: DocumentsTableHandlers['handleTableSettingsChange'] = async tableSettings => {
    try {
      const limit =
        tableSettings[elements.DOCUMENTS_TABLE_SETTINGS.ROWS_COUNT] ??
        props.rowsCountOptions?.[0]?.value ??
        constants.DEFAULT_ROWS_COUNT;
      const offset = constants.DEFAULT_OFFSET;

      if (
        reduxState.isAuthenticated &&
        props.shouldUseSavedTableSettings &&
        props.tableSettingsKey
      ) {
        localActions.setIsLoading(true);

        await userReduxActions.updateUserDocsTableSettings(props.tableSettingsKey, {
          [userConstants.RELATED_DOCS_TABLE_SETTINGS_KEYS.LIMIT]: limit
        } as RelatedDocsTableSettings);

        localActions.setIsLoading(false);
      }

      if (props.shouldUseCustomOffsets) {
        localActions.setDefaultOffset(null);
      }

      localActions.setTableParams({
        [uiLib.TABLE_PARAMS.LIMIT]: limit,
        [uiLib.TABLE_PARAMS.OFFSET]: offset
      });
      localActions.setTableRefreshTrigger(tableRefreshTrigger => !tableRefreshTrigger);
    } catch (e) {
      errorUtils.logError(e as Error);

      localActions.setIsLoading(false);
    }
  };

  const handleTableParamsChange: DocumentsTableHandlers['handleTableParamsChange'] = useCallback(
    (params: uiLib.Params) => {
      localActions.setTableParams(params);

      if (props.shouldUseCustomOffsets) {
        if (localState.isInitialRequest) {
          localActions.setIsInitialRequest(false);
        } else if (
          !lodash.isNil(params?.[uiLib.TABLE_PARAMS.OFFSET]) &&
          params?.[uiLib.TABLE_PARAMS.OFFSET] !== prevOffset
        ) {
          localActions.setTableRefreshTrigger(tableRefreshTrigger => !tableRefreshTrigger);
        }
      }
    },
    [localActions, localState.isInitialRequest, prevOffset, props.shouldUseCustomOffsets]
  );

  const handleRequestParamsChange: DocumentsTableHandlers['handleRequestParamsChange'] = useCallback(() => {
    if (!lodash.isEqual(props.requestParams, prevProps.requestParams)) {
      if (props.shouldUseCustomOffsets) {
        localActions.setDefaultOffset(null);
      }
      localActions.setTableParams(
        dataHelpers.getInitialTableParams({
          shouldUseSavedTableSettings: props.shouldUseSavedTableSettings,
          currentUserTableSettings: reduxState.currentUserTableSettings,
          rowsCountOptions: props.rowsCountOptions
        })
      );
      localActions.setTableRefreshTrigger(tableRefreshTrigger => !tableRefreshTrigger);
    }
  }, [
    localActions,
    prevProps.requestParams,
    props.requestParams,
    props.rowsCountOptions,
    props.shouldUseCustomOffsets,
    props.shouldUseSavedTableSettings,
    reduxState.currentUserTableSettings
  ]);

  const handleSortChange: DocumentsTableHandlers['handleSortChange'] = sort => {
    localActions.setSort(sort as Parameters<typeof localActions.setSort>[0]);
    if (props.shouldUseCustomOffsets || localState.tableParams[uiLib.TABLE_PARAMS.OFFSET] === 0) {
      // need to trigger table refresh, offset will be reset automatically
      localActions.setTableRefreshTrigger(tableRefreshTrigger => !tableRefreshTrigger);
    } else {
      // offset should be reset manually and table refresh will cause double requests
      localActions.setTableParams(params => ({ ...params, [uiLib.TABLE_PARAMS.OFFSET]: 0 }));
    }
  };

  const handleTableSettingsInitialLoad: DocumentsTableHandlers['handleTableSettingsInitialLoad'] = useCallback(async () => {
    if (reduxState.isAuthenticated && reduxState.areCurrentUserTableSettingsReady) {
      localActions.setTableParams(
        dataHelpers.getInitialTableParams({
          shouldUseSavedTableSettings: props.shouldUseSavedTableSettings,
          currentUserTableSettings: reduxState.currentUserTableSettings,
          rowsCountOptions: props.rowsCountOptions
        })
      );
      localActions.setTableRefreshTrigger(tableRefreshTrigger => !tableRefreshTrigger);
    }
  }, [
    localActions,
    props.rowsCountOptions,
    props.shouldUseSavedTableSettings,
    reduxState.areCurrentUserTableSettingsReady,
    reduxState.currentUserTableSettings,
    reduxState.isAuthenticated
  ]);

  const handleTableRefresh: DocumentsTableHandlers['handleTableRefresh'] = () => {
    localActions.setTableRefreshTrigger(refreshTrigger => !refreshTrigger);
  };

  const handleVisibleFieldsChange: DocumentsTableHandlers['handleVisibleFieldsChange'] = useCallback(
    columns => {
      const visibleColumns = columns.map(
        column => column.dataKey as uiLib.DOCUMENT_TOGGLEABLE_FIELD
      );
      if (!lodash.isEqual(visibleColumns, localState.visibleDocFields)) {
        localActions.setVisibleDocFields(visibleColumns);
      }
    },
    [localActions, localState.visibleDocFields]
  );

  const handleSortStateToUrlReflection: DocumentsTableHandlers['handleSortStateToUrlReflection'] = useCallback(() => {
    navigate(
      uiLib.formatRedirectUrl({
        path: location.pathname,
        params: {
          ...queryParams,
          [filterConstants.SUPPORTED_QUERY_PARAMS.ORDER]: localState.sort.sortOrder,
          [filterConstants.SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: localState.sort.sortType
        }
      }),
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.sort, location.pathname]);

  return {
    handleDocumentsFetch,
    handleDocumentsIdsFetch,
    handleSortChange,
    handleSelectedDocumentsChange,
    handleTableSettingsChange,
    handleTableParamsChange,
    handleRequestParamsChange,
    handleTableSettingsInitialLoad,
    handleTableRefresh,
    handleVisibleFieldsChange,
    handleSortStateToUrlReflection,
    handleRouteChange
  };
};
