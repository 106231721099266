import * as sentenceNotesApi from '../../sentenceNotes.api';
import * as sortConstants from 'constants/Sort';
import * as sentenceNotesConstants from '../../sentenceNotes.constants';

export const INITIAL_PARAMS: Pick<
  Required<Parameters<typeof sentenceNotesApi.getSentenceNotes>[0]>,
  'limit' | 'offset' | 'sort_by' | 'sort_order'
> = {
  limit: 20,
  offset: 0,
  sort_by: 'first_sentence_id',
  sort_order: sortConstants.SORT_ORDER.ASC
};

export const LOADER_MESSAGES: Record<sentenceNotesConstants.SENTENCE_NOTE_TYPE, string[]> = {
  [sentenceNotesConstants.SENTENCE_NOTE_TYPE.ANNOTATIONS]: [
    'Loading more annotations...',
    'Populating annotation list...'
  ],
  [sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS]: [
    'Loading more obligations...',
    'Populating obligation list...'
  ]
};
