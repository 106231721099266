import type { useObligationWorkspaceData } from './useObligationWorkspaceData';

import * as constants from '../../../Overlay.constants';
import * as queryKeysConstants from 'constants/QueryKeys';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks';
import { useBucketsReduxActions } from 'shared/features/buckets/hooks';
import { useObligationWorkspaceHandlers } from './useObligationWorkspaceHandlers';

export const useObligationWorkspaceQueries = ({
  localActions,
  reduxState,
  onFetchHTMLSuccess
}: {
  localActions: ReturnType<typeof useObligationWorkspaceData>['localActions'];
  reduxState: ReturnType<typeof useObligationWorkspaceData>['reduxState'];
  onFetchHTMLSuccess: ReturnType<
    typeof useObligationWorkspaceHandlers
  >['handleSentenceNoteSelectionInRightPanel'];
}) => {
  const documentReduxActions = useDocumentsReduxActions();
  const bucketReduxActions = useBucketsReduxActions();

  const fetchHTML = useQuery({
    queryKey: [queryKeysConstants.QUERY_KEYS.DOCUMENT_HTML, reduxState.documentId],
    queryFn: async () => {
      const { response: sentenceHtml } = await documentReduxActions.fetchSentences(
        [reduxState.documentId],
        true,
        constants.SENTENCE_NOTES_REQUEST_CONTENT_TYPE
      );

      localActions.setSentenceHtml(sentenceHtml || '');

      onFetchHTMLSuccess();

      return sentenceHtml;
    },
    enabled: Boolean(reduxState.documentId),
    refetchOnWindowFocus: false
  });

  const fetchAccessibleBucketsCount = useQuery({
    queryKey: [queryKeysConstants.QUERY_KEYS.BUCKETS],
    queryFn: async () => {
      const { total: accessibleBucketsCount } = await bucketReduxActions.getBuckets({
        limit: 0,
        offset: 0,
        is_editable: true
      });

      localActions.setAccessibleBucketsCount(accessibleBucketsCount ?? 0);

      return accessibleBucketsCount;
    },
    refetchOnWindowFocus: false
  });

  return {
    fetchHTML,
    fetchAccessibleBucketsCount
  };
};
